import logger from './logger';
import { ModelConfig } from '../config/model-config';
import dayjs from 'dayjs';
import util from '../util.js';

const getList = async ({ gridColumns, setIsLoading, setData, page, pageSize, sortModel, filterModel, api, parentFilters, action = 'list', grid, isForDashboard, extraParams, baseFilters }) => {

    setIsLoading(true);
    const lookups = {};
    gridColumns.forEach(({ lookup, relationType, field }) => {
        if (!lookup) {
            return;
        }
        const lookupInfo = ModelConfig.getLookup(lookup);
        if (!lookupInfo) {
            return;
        }
        const relationName = lookup;
        if (!lookups[relationName]) {
            lookups[relationName] = [];
        }
        if (!lookups[relationName].includes(lookupInfo)) {
            lookups[relationName].push(lookupInfo);
        }
    });

    const searchParams = new URLSearchParams();
    searchParams.set("start", page * pageSize);
    searchParams.set("limit", pageSize);
    searchParams.set("grid", grid);

    if (sortModel.length) {
        const sort = sortModel.map(sort => {
            const sortColumn = gridColumns.filter((item) => item.field === sort.field);
            const sortColumnValue = sortColumn[0]?.lookup ? `${sortColumn[0].lookup}.${lookups[sortColumn[0].lookup][0]}` : sort.field;
            return { sort: sortColumnValue, dir: sort.sort }
        });
        searchParams.set("sort", JSON.stringify(sort));
    }

    const where = [];
    if (filterModel?.items?.length) {
        filterModel.items.map(filter => {
            const column = gridColumns.filter((item) => item.field === filter.columnField);
            const filterColumnValue = column[0]?.lookup ? `${column[0].lookup}.${lookups[column[0].lookup][0]}` : null;
            where.push({
                field: filter.columnField,
                operator: filter.operatorValue,
                value: filter.value,
                elasticFilter: column[0]?.elasticFilter,
                type: column[0]?.type,
                filterColumn: filterColumnValue
            });
        });
    }
    if (parentFilters) {
        const updatedParentFilters = { ...parentFilters };
        const dateFilterIndex = Object.keys(updatedParentFilters).findIndex(ele => ele === 'Date');
        const sensorIdFilterIndex = Object.keys(updatedParentFilters).findIndex(ele => ele === 'sensorId');
        const sensorTypeIdFilterIndex = Object.keys(updatedParentFilters).findIndex(ele => ele === 'sensorTypeId');
        if (isForDashboard && grid === 'case') {
            if (dateFilterIndex > -1) {
                updatedParentFilters.startDateTime = { operator: 'isOnOrAfter', value: updatedParentFilters.Date.startDate };
                updatedParentFilters.endDateTime = { operator: 'isOnOrBefore', value: updatedParentFilters.Date.endDate };
                delete updatedParentFilters.Date;
            }
            if (sensorIdFilterIndex > -1) {
                delete updatedParentFilters.sensorId;
            }
            if (sensorTypeIdFilterIndex > -1) {
                delete updatedParentFilters.sensorTypeId;
            }
        }
        if (isForDashboard && grid === 'sensor') {
            updatedParentFilters.isActive = { operator: 'equals', value: true };
            if (dateFilterIndex > -1) {
                delete updatedParentFilters.Date;
            }
        }
        const filterKeys = Object.keys(updatedParentFilters);
        filterKeys.forEach(key => {
            const isWhereEmpty = where.filter(ele => ele.field === key).length === 0;
            const applyFilter = isForDashboard && grid !== 'sensor' && grid !== 'alert' && key === 'sensorId' ? false : true;
            if (applyFilter) {
                if (isWhereEmpty) {
                    const isParentFilterKeyArray = Array.isArray(updatedParentFilters[key]);
                    const operator = isParentFilterKeyArray ? 'in' : typeof updatedParentFilters[key] === 'object' ? updatedParentFilters[key].operator : 'equals';
                    const value = isParentFilterKeyArray ? updatedParentFilters[key] : typeof updatedParentFilters[key] === 'object' ? updatedParentFilters[key].value : updatedParentFilters[key];
                    const filteredValue = [];
                    const shouldUpdateValue = isForDashboard && typeof value === 'object' && !(key.toLocaleLowerCase().includes('date')) && ((value[0].includes(',')) || grid === 'sensor');
                    if (shouldUpdateValue) {
                        value.map((ele) => {
                            const splittedEle = ele.split(',');
                            splittedEle.map((element) => {
                                const updatedValue = grid === 'alert' ? `'${element.replaceAll(`'`, '')}'` : element.replaceAll(`'`, '');
                                filteredValue.push(updatedValue);
                            })
                        })
                    }

                    if (key === 'Date' && updatedParentFilters[key].operator === 'between') {
                        where.push({
                            field: key,
                            operator: updatedParentFilters[key].operator,
                            startDate: dayjs(updatedParentFilters[key].startDate).format('YYYY-MM-DD'),
                            endDate: dayjs(updatedParentFilters[key].endDate).format('YYYY-MM-DD')
                        });
                    }
                    else {
                        where.push({
                            field: key,
                            operator: isParentFilterKeyArray ? operator : (typeof updatedParentFilters[key] === 'object' && !Array.isArray(updatedParentFilters[key]) ? updatedParentFilters[key].operator : 'equals'),
                            value: typeof updatedParentFilters[key] === 'object' && !Array.isArray(updatedParentFilters[key]) ? updatedParentFilters[key].value : updatedParentFilters[key]
                        });
                    }
                }
            }
        });
    }
    searchParams.set("where", JSON.stringify(where));

    if (lookups) {
        searchParams.set("lookups", JSON.stringify(lookups));
    }
    if (extraParams) {
        searchParams.set("extraParams", JSON.stringify(extraParams));
    }
    if (baseFilters) {
        searchParams.set("baseFilters", JSON.stringify(baseFilters));
    }
    const response = await fetch(`${api}/${action}?${searchParams.toString()}`);
    if (response.status === util.statusCode.success) {
        const data = await response.json();
        setData({ rows: data.result?.rows || data.result, rowCount: data.count, hospitals: data.result?.hospitals, sensorIds: data.result?.sensorIds });
        setIsLoading(false);
        return true;
    } else {
        if (response.status === util.statusCode.unauthorized) {
            logger.info('Session Expired!');
            setTimeout(() => {
                window.location.href = '/';
            }, 2000);
        } else {
            logger.error('Failed to load list', response.body);
            setIsLoading(false);
            return false;
        }
    }
};
const getRecord = async ({ api, id, setIsLoading, setActiveRecord, modelConfig, parentFilters, where = {}, isDashboard = false }) => {
    setIsLoading(true);
    const searchParams = new URLSearchParams();
    const url = `${api}/${id === undefined || id === null ? '-' : id}`;
    const lookupsToFetch = {};
    modelConfig.formDef.forEach(field => {
        if (field.lookup) {
            lookupsToFetch[field.lookup] = true;
        }
    });
    searchParams.set("lookups", Object.keys(lookupsToFetch).join(','));
    if (where && Object.keys(where).length) {
        searchParams.set("where", JSON.stringify(where));
    };
    searchParams.set("isDashboard", isDashboard);
    if(modelConfig.module!=="alertsHistory" && modelConfig.linkColumn!=="Date"){
        
        fetch(`${url}?${searchParams.toString()}`)
            .then(response => {
                if (response.status === util.statusCode.success) {
                    response.json().then(responseData => {
                        const { data: record, lookups } = responseData;
                        let title = record[modelConfig.linkColumn];
                        const columnConfig = modelConfig.columns.find(a => a.field === modelConfig.linkColumn);
                        if (columnConfig && columnConfig.lookup) {
                            if (lookups && lookups[columnConfig.lookup] && lookups[columnConfig.lookup].length) {
                                const lookupValue = lookups[columnConfig.lookup].find(a => a.value === title);
                                if (lookupValue) {
                                    title = lookupValue.label;
                                }
                            }
                        }
    
                        setActiveRecord({ id, title: title, record: { ...record, ...parentFilters }, lookups });
                    });
                } else {
                    if (response.status === util.statusCode.unauthorized) {
                        logger.info('Session Expired!');
                        setTimeout(() => {
                            window.location.href = '/';
                        }, 2000);
                    } else {
                        logger.error('Could not load record', new Error(response.body.toString()));
                    }
                }
            })
            .catch(error => {
                logger.error('Could not load record', error);
            })
            .finally(() => setIsLoading(false));
    }
};
const deleteRecord = async function ({ id, api }) {
    if (!id) {
        logger.error('Deleted failed. No active record.');
        return;
    }
    try {
        const response = await fetch(`${api}/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.status === util.statusCode.success) {
            return true;
        }
        if (response.status === util.statusCode.unauthorized) {
            logger.info('Session Expired!');
            setTimeout(() => {
                window.location.href = '/';
            }, 2000);
        } else {
            logger.error('Deleted failed', response.body);
        }
    } catch (error) {
        logger.error('Deleted failed', error);
    }
    return false;
};
const saveRecord = async function ({ id, api, values }) {
    let url, method;

    if (id) {
        url = `${api}/${id}`;
        method = 'PUT';
    } else {
        url = api;
        method = 'POST';
    }


    try {
        const response = await fetch(url, {
            method,
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.status === util.statusCode.success) {
            //const result = response.json();
            if(url.indexOf('logout') > -1) {
                return response.json();
            }
            return true;
        }
        if (response.status === util.statusCode.unauthorized) {
            logger.info('Session Expired!');
            setTimeout(() => {
                window.location.href = '/';
            }, 2000);
        } else {
            response.text().then(async function (text) {
                const { error } = JSON.parse(text)
                logger.error(`Save failed: ${error}`, error);
            });
        }
    } catch (error) {
        logger.error('Save failed', error);
    }
    return false;
};
const exportRecord = async function ({ api, gridColumns, filterModel, sortModel, parentFilters, type, title, action, allColumns, setIsLoading, grid, isForDashboard, extraParams, baseFilters, limit=0 }) {
    try {
        setIsLoading(true);
        const lookups = {}, activeColumns = [];
        if (allColumns) {
            Object.entries(allColumns).forEach(([key, value]) => {
                if (value) {
                    activeColumns.push(key);
                }
            });
        }
        gridColumns.forEach(({ lookup, relationType, field }) => {
            if (!lookup) {
                return;
            }
            const lookupInfo = ModelConfig.getLookup(lookup);
            if (!lookupInfo) {
                return;
            }
            const relationName = lookup;
            if (!lookups[relationName]) {
                lookups[relationName] = [];
            }
            if (!lookups[relationName].includes(lookupInfo)) {
                lookups[relationName].push(lookupInfo);
            }
        });

        const searchParams = new URLSearchParams();
        searchParams.set("limit", limit);
        searchParams.set("grid", grid);

        if (sortModel.length) {
            const sort = sortModel.map(sort => {
                const sortColumn = gridColumns.filter((item) => item.field === sort.field);
                const sortColumnValue = sortColumn[0]?.lookup ? `${sortColumn[0].lookup}.${lookups[sortColumn[0].lookup][0]}` : sort.field;
                return { sort: sortColumnValue, dir: sort.sort }
            });
            searchParams.set("sort", JSON.stringify(sort));
        }

        const where = [];
        if (filterModel?.items?.length) {
            filterModel.items.map(filter => {
                const column = gridColumns.filter((item) => item.field === filter.columnField);
                const filterColumnValue = column[0]?.lookup ? `${column[0].lookup}.${lookups[column[0].lookup][0]}` : null;
                where.push({
                    field: filter.columnField,
                    operator: filter.operatorValue,
                    value: filter.value,
                    elasticFilter: column[0]?.elasticFilter,
                    type: column[0]?.type,
                    filterColumn: filterColumnValue
                });
            });
        }
        if (parentFilters) {
            const updatedParentFilters = { ...parentFilters };
            const dateFilterIndex = Object.keys(updatedParentFilters).findIndex(ele => ele === 'Date');
            const roomIdFilterIndex = Object.keys(updatedParentFilters).findIndex(ele => ele === 'roomId');
            const sensorIdFilterIndex = Object.keys(updatedParentFilters).findIndex(ele => ele === 'sensorId');
            if (isForDashboard && grid === 'case') {
                if (dateFilterIndex > -1) {
                    updatedParentFilters.startDateTime = { operator: 'isOnOrAfter', value: dayjs(updatedParentFilters.Date.startDate).format('YYYY-MM-DD') };
                    updatedParentFilters.endDateTime = { operator: 'isOnOrBefore', value: dayjs(updatedParentFilters.Date.endDate).format('YYYY-MM-DD') };
                    delete updatedParentFilters.Date;
                }
                if (roomIdFilterIndex > -1) {
                    delete updatedParentFilters.roomId;
                }
                if (sensorIdFilterIndex > -1) {
                    delete updatedParentFilters.sensorId;
                }
            }
            if (isForDashboard && grid === 'sensor') {
                updatedParentFilters.isActive = { operator: 'equals', value: true };
                if (dateFilterIndex > -1) {
                    delete updatedParentFilters.Date;
                }
            }
            const filterKeys = Object.keys(updatedParentFilters);
            filterKeys.forEach(key => {
                const isWhereEmpty = where.filter(ele => ele.field === key).length === 0;
                const applyFilter = isForDashboard && grid !== 'sensor' && grid !== 'alert' && key === 'sensorId' ? false : true;
                if (applyFilter) {
                    if (isWhereEmpty) {
                        const isParentFilterKeyArray = Array.isArray(updatedParentFilters[key]);
                        const operator = isParentFilterKeyArray ? 'in' : typeof updatedParentFilters[key] === 'object' ? updatedParentFilters[key].operator : 'equals';
                        const value = isParentFilterKeyArray ? updatedParentFilters[key] : typeof updatedParentFilters[key] === 'object' ? updatedParentFilters[key].value : updatedParentFilters[key];
                        const filteredValue = [];
                        const shouldUpdateValue = isForDashboard && typeof value === 'object' && !(key.toLocaleLowerCase().includes('date')) && ((value[0].includes(',')) || grid === 'sensor');
                        if (shouldUpdateValue) {
                            value.map((ele) => {
                                const splittedEle = ele.split(',');
                                splittedEle.map((element) => {
                                    const updatedValue = grid === 'alert' ? `'${element.replaceAll(`'`, '')}'` : element.replaceAll(`'`, '');
                                    filteredValue.push(updatedValue);
                                })
                            })
                        }
                        if (key === 'Date' && parentFilters[key].operator === 'between') {
                            where.push({
                                field: key,
                                operator: parentFilters[key].operator,
                                startDate: parentFilters[key].startDate,
                                endDate: parentFilters[key].endDate,
                            });
                        }
                        else {
                            where.push({
                                field: key,
                                operator: isParentFilterKeyArray ? operator : (typeof updatedParentFilters[key] === 'object' && !Array.isArray(updatedParentFilters[key]) ? updatedParentFilters[key].operator : 'equals'),
                                value: typeof updatedParentFilters[key] === 'object' && !Array.isArray(updatedParentFilters[key]) ? updatedParentFilters[key].value : updatedParentFilters[key]
                            });
                        }
                    }
                }
            });
        }
        searchParams.set("where", JSON.stringify(where));

        if (lookups) {
            searchParams.set("lookups", JSON.stringify(lookups));
        }

        searchParams.set("column", JSON.stringify(gridColumns));
        searchParams.set('type', type);
        searchParams.set('title', title);
        searchParams.set('action', action);
        searchParams.set('activeColumns', activeColumns);
        if (extraParams) {
            searchParams.set('extraParams', JSON.stringify(extraParams));
        }
        if (baseFilters) {
            searchParams.set("baseFilters", JSON.stringify(baseFilters));
        }

        const response = await fetch(`${api}/export`, {
            method: 'POST',
            body:searchParams
        });
        if (response.status === util.statusCode.success) {
            const toReturn = await response.blob();
            setIsLoading(false);
            return toReturn;
        }
        if (response.status === util.statusCode.unauthorized) {
            logger.info('Session Expired!');
            setTimeout(() => {
                window.location.href = '/';
            }, 2000);
        } else {
            logger.error('Export failed', response.body);
        }
    } catch (error) {
        logger.error('Export failed', error);
    }
    setIsLoading(false);
    return false;
};

const loadLookups = async function ({ lookups, setActiveRecord, api, method = 'POST' }) {
    try {
        const params = { lookups };
        const response = await fetch(api, {
            method,
            body: JSON.stringify(params),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.status === util.statusCode.success) {
            response.json().then(responseData => {
                const { result } = responseData;
                setActiveRecord({ result });
            });
        } else {
            if (response.status === util.statusCode.unauthorized) {
                logger.info('Session Expired!');
                setTimeout(() => {
                    window.location.href = '/';
                }, 2000);
            } else {
                logger.error('Could not load lookups', new Error(response.body.toString()));
            }
        }
    } catch (error) {
        logger.error('Loading lookup failed', error);
    }
};
export {
    getList,
    getRecord,
    deleteRecord,
    saveRecord,
    exportRecord,
    loadLookups
};
