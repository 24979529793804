const config = {
    title: 'Door Movement Activity History',
    name: 'doorMovementHistory',
    defaultSort: 'Date desc',
    api: 'telemetry/summary',
    controllerType: 'elastic',
    readOnly: true,
    isClient: false,
    module: 'door',
    columns: [
        { field: "name", flex: 1, header: "Sensor", lookup: 'sensor', required: true, hideable: false, sortable: false, filterable: false },
        { field: "sensorCode", width: 100, header: "Sensor Code", type: 'string', readOnly: true, sortable: false, filterable: false },
        { field: "Date", width: 220, header: "Open Date/time stamp", type: "date",  time: true, showDateOnly: false, isUtc: true, isLocal: false },
        { field: "EndTime", width: 220, header: "Close Date/time stamp", type: "date",  time: true, showDateOnly: false, isUtc: true, isLocal: false },
        // { field: "duration", flex: 1, header: "Duration", type: "string" },
        { field: "State", width: 100, header: "Reading", type: "number", renderCell: (params) => params.row?.State === 2 ? "Closed" : "Open", hideConfig: { onHide: () => { return { isHide: true } } }, sortable: false, filterable: false }
    ]
};

export default config;
