import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import 'fontsource-roboto';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import { theme } from './theme';
import './styles/index.css';

LicenseInfo.setLicenseKey('cc97b7d6858f3e0a42873e332a75e44cT1JERVI6NDE5MDIsRVhQSVJZPTE2ODE1NzcyNzAwMDAsS0VZVkVSU0lPTj0x');//TODO move into env/config file

export const ColorModeContext = React.createContext({ toggleColorMode: () => { } });
const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container);

const Main_app = () => {

    const [mode, setMode] = React.useState('dark');
    const colorMode = React.useMemo(() => ({
        toggleColorMode: () => {
            setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        }
    }), []);

    const current_theme = React.useMemo(() => createTheme(theme(mode)), [mode]);

    return <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={current_theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <App />
                <ToastContainer />
            </ThemeProvider>
    </ColorModeContext.Provider>
}
root.render(
    <Main_app />
);
