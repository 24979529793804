const baseEndpoint = "api";
const api = {
    base: baseEndpoint,
    hospitalsEndpoint: "api/dips",
};

export { api };

export const appRoles = {
    administrator: "administrator",
    biomedical: "biomedicals",
    surgeon: "surgeon",
}