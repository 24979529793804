const config = {
    title: 'Case',
    defaultSort: 'startDateTime desc',
    linkColumn: 'caseNumber',
    module: 'case',
    securityFilter: {
        hospitalId: "room:hospital"
    },
    columns: [
        { field: "caseNumber", width: 120, header: "Case Number", required: true, hideable: false },
        { field: "MRN", header: "MRN", required: true },
        { field: "roomId", width: 150, header: "Room", lookup: "room", required: true },
        { field: "startDateTime", width: 200, header: "Start Date/Time", required: true, type: 'date', defaultValue: new Date(), time: true, },
        { field: "endDateTime", width: 200, header: "End Date/Time", required: true, type: 'date', defaultValue: new Date(), validate: 'compare:startDateTime', time: true, },
        { field: "surgeonId", width: 120, header: "Surgeon", lookup: "user", required: true },
        { field: "specialty", width: 120, header: "Specialty", required: true, minLength: 3, maxLength: 20 },
        { field: "procedureType", width: 120, header: "Procedure Type", required: true, minLength: 3, maxLength: 15 },
        { field: "closing", width: 100, header: "Closing", required: true, type: 'boolean' },
        { field: "patientIn", width: 120, header: "Patient In OR", required: true },
        { field: "patientOut", width: 120, header: "Patient Out OR", required: true },
        { field: "anesthesiaStart", width: 180, header: "Anesthesia Start", required: true, type: 'date', time: true, },
        { field: "anesthesiaStop", width: 180, header: "Anesthesia Stop", required: true, type: 'date', time: true, },
        { field: "location", width: 120, header: "Location", required: true },
        { field: "procedureDescription", width: 180, header: "Procedure Description", required: true }
    ]
};

export default config;
