import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../contexts/userContext';
import Copyright from '../Components/common/Copyright';


const theme = createTheme();

const Login = () => {
    const [userContext, setUserContext] = React.useContext(UserContext);
    const [accountType, setAccountType] = useState(true);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const getUser = async (accountType) => {
        const result = await fetch('/login'); // user data
        result.text().then(async function (text) {
            const { success, user: userData } = JSON.parse(text);

            // userData.username = 'john_thomas@dips.com';

            setAccountType(accountType);
            accountType = accountType ?? userData.accountType;
            if (!accountType) {
                setLoading(false);
            }
            const favicon = document.getElementById("favicon");
            favicon.href = `${userData.docTitle}.ico`;
            document.title = userData?.docTitle;
            if (success) {
                setUserContext(userData);
                const pathname = location?.state?.pathName;
                // eslint-disable-next-line no-restricted-globals
                navigate(pathname ?? "/");
            } else {
                if (accountType) {
                    // eslint-disable-next-line no-restricted-globals
                    window.location.href = window.location.origin.replace(':4000', ':3000').replace('http://', 'https://') + `/auth/login/${accountType}`; //msal
                }
            }
        });
        return result;
    }

    React.useEffect(() => {
        async function fetchData() {
            setLoading(true);
            await getUser();
        }
        fetchData();
    }, []);

    return (
        <>
            <ThemeProvider theme={theme}>
                {(!loading) && <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Login
                        </Typography>
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <Button
                                type="button"
                                onClick={() => getUser('azuread')}
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Corporate Account
                            </Button>
                            <Button
                                type="button"
                                onClick={() => getUser('personal')}
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Personal Account
                            </Button>
                        </Box>
                    </Box>
						<Copyright
							textProps={{ sx: { mt: 8, mb: 4 }, variant: 'body2', color: 'text.secondary', align: 'center' }}
							linkProps={{
								style: { color: 'inherit' }
							}}
						/>
					</Container>
				}
			</ThemeProvider>
		</>
	);
}

export default Login;
