import modelConfig, { ModelConfig } from './model-config';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SensorsOutlinedIcon from '@mui/icons-material/SensorsOutlined';
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import modelConfigs, { hospital, user, room, sensor, role, sensorType, lookupTypes, mqttClient, telemetry, telemetrySummary, cases, temperatureHistoryReport, doorReport, doorMovementReport, dashboardAlert, alertDefinition, alertDefinitionSensor, alertRecipient, notifications, myAlertsHistory, humidityDailySummary, doorMovementAverage, temperatureDailySummary, humidityHistory, alertsHistory } from 'dips-models';
import { HomeIcon, CasesIcon, Mqtt_clientsIcon, TelemetriesIcon, Alert_definitionsIcon } from '../assets/icons'


const models = [
    modelConfig.combineConfig({ ...room, title: 'Room Association', icon: HomeIcon }),
    modelConfig.combineConfig({ ...sensor, icon: SensorsOutlinedIcon }),
    modelConfig.combineConfig({ ...user, icon: AirlineSeatFlatIcon, parentKey: 'Setup' }),
    modelConfig.combineConfig({ ...sensorType, icon: AdminPanelSettingsIcon, parentKey: 'Setup' }),
	modelConfig.combineConfig({ ...hospital, icon: ApartmentIcon, parentKey: 'Setup' }),
	modelConfig.combineConfig({ ...role, icon: AdminPanelSettingsIcon, parentKey: 'Setup' }),
    modelConfig.combineConfig({ ...mqttClient, icon: Mqtt_clientsIcon }),
    modelConfig.combineConfig({ ...telemetry, icon: TelemetriesIcon, parentKey: 'System Health' }),
    modelConfig.combineConfig({ ...telemetrySummary, icon: TelemetriesIcon }),
    modelConfig.combineConfig({ ...cases, icon: CasesIcon }),
    modelConfig.combineConfig({ ...sensor, icon: AdminPanelSettingsIcon, parentKey: 'Reports' }),
    modelConfig.combineConfig({ ...temperatureHistoryReport }),
    modelConfig.combineConfig({ ...doorReport }),
    modelConfig.combineConfig({ ...doorMovementReport }),
    modelConfig.combineConfig({ ...dashboardAlert }),
    modelConfig.combineConfig({ ...alertDefinition, icon: Alert_definitionsIcon }),
    modelConfig.combineConfig({ ...alertDefinitionSensor }),
    modelConfig.combineConfig({ ...alertRecipient }),
    modelConfig.combineConfig({ ...notifications }),
    modelConfig.combineConfig({ ...myAlertsHistory }),
    modelConfig.combineConfig({ ...humidityDailySummary }),
    modelConfig.combineConfig({ ...doorMovementAverage }),
	modelConfig.combineConfig({ ...temperatureDailySummary }),
	modelConfig.combineConfig({ ...humidityHistory }),
	modelConfig.combineConfig({ ...alertsHistory })
];

lookupTypes.forEach((lookupType) => {
    const config = modelConfigs.find((modelConfig) => modelConfig.name === lookupType);
    ModelConfig.registerLookup({
        name: lookupType,
        displayField: config.displayField || config.linkColumn || (typeof config.defaultSort === 'string' ? config.defaultSort : 'code')
    });
});

export default models;
