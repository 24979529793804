const config = {
    title: 'Door Movement Average',
    name: "doorMovementAverage",
    defaultSort: 'Date desc',
    api: 'telemetry/summary',
    controllerType: 'elastic',
    readOnly: true,
    module: 'door',
    columns: [
        { field: "hospitalName", flex: 1, header: "Hospital", type: "string", sortable: false, filterable: false },
        { field: "roomName", width: 120, header: "Room", type: 'string', sortable: false, filterable: false },
        { field: "sensorId", flex: 1, header: "Sensor", type: 'string', sortable: false, filterable: false },
        { field: "duration", width: 120, header: "Duration", type: "string", filterable: false, sortable: false },
        { field: "Date", flex: 1, header: "Date/time stamp", type: "date",  time: false, showDateOnly: true, isLocal: false },
        { field: "totalMovements", width: 100, header: "Open Count", type: "number", sortable: false, filterable: false }
    ]
};

export default config;
