const config = {
    title: 'Room',
    displayField: 'name',
    defaultSort: 'code',
    module: 'room',
    listTitle:'Room Association',
    securityFilter: {
        hospitalId: "hospital"
    },
    columns: [
        { field: "code", width: 120, header: "Code", required: true, minLength: 2, maxLength: 10, hideable: false, maxWidth: 300 },
        { field: "name", flex: 1, header: "Name", required: true, minLength: 2, maxLength: 30, maxWidth: 300 },
        { field: "hospitalId", flex: 1, header: "Hospital", lookup: "hospital", required: true },
        { field: "isActive", header: "Active", type: 'boolean', defaultValue: true, flex: 1 },
    ],
    relations: [
        'sensor',
        'telemetry',
        'telemetrySummary',
        'case'
    ]
};

export default config;
