import { Container, Grid, Typography, Paper, Box } from '@mui/material';
import { dateFormats, sensorDescription } from 'dips-models';
import { useTheme } from '@mui/styles';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';

import { api as apiDefs } from '../config/authConfig';
import { getList } from '../Components/crud-helper';
import { useDataFilter } from '../contexts/dataFiltersContext';
import { UserContext } from '../contexts/userContext';
// import BarChart from '../Components/common/BarChart';
import Filters from '../Components/Filters';
import FormBase from './form-base';
import models from '../config/models';
import util from '../util';
import MultiLineChart from '../Components/common/MultiLineChart';


const { reportDurationDateFormat, reportCreatedDateFormat } = dateFormats;
const dateFormat = 'YYYY-MM-DD';


const humidityHistoryModel = models.filter((ele) => ele.name === 'humidityHistory')[0];

const humidityHistory = { ...humidityHistoryModel };
const baseLookupParams = [
	{ lookupType: 'hospital' },
	{ lookupType: 'room' },
	{
		lookupType: 'sensor',
		// include: [{ model: 'sensorType', as: 'sensorType' }],
		// where: { '$sensorType.profileId$': profileForHumidityhistory },
		where: { '$sensor.telemetryDescription$': sensorDescription.humidity }

	}
];

function HumidityHistory(props) {
	const [userContext] = React.useContext(UserContext);
	const [chartData, setChartData] = useState({});
	const [sensorList, setSensorList] = useState({});
	const [label, setLabel] = useState({ roomLabel: '', sensorLabel: '', hospitalLabel: '' });
	const { palette } = useTheme();
	const [hospitalsFilterData, setHospitalsFilterData] = useState([]);
	const [roomsFilterData, setRoomsFilterData] = useState([]);
	const [sensorFilterData, setSensorFilterData] = useState([]);
	const [parentFilters, setParentFilters] = useDataFilter({
		// ProfileId: profileForHumidityhistory,
		Date: {
			operator: 'between',
			startDate: dayjs().subtract(6, 'day').format(dateFormat),
			endDate: dayjs().format(dateFormat)
		},
		Description: 'Humidity'
	});
	const extraParams = {
		report: 'HumidityHistory',
		indexName: 'telemetry',
		valueField: 'Data'
	};
	const printHospitalVal=util.printHospital(hospitalsFilterData,parentFilters);
	useEffect(() => {
		let parentF = { ...parentFilters };
		getList({
			page: 0,
			pageSize: 10,
			sortModel: [],
			gridColumns: [],
			grid: humidityHistory.name,
			api: `${apiDefs.base}/telemetry/summary`,
			setIsLoading: () => {},
			setData: (data) => {
				let sensorData = {};
				let list = {};
				if (data && data.rows && data.rows.length > 0) {
					data.rows.forEach((entry) => {
						let sensor = entry?.SensorId;
						if (!list[sensor]) {
							list[sensor] = { sensorId: sensor, sensorName: entry?.sensorId };
						}
						let newEntry = { ...entry };
						if(!sensorData[sensor]){
							sensorData[sensor] = [];
						}
						sensorData[sensor].push(newEntry);
					});

					for (let sensor in sensorData) {
						sensorData[sensor] = sensorData[sensor]?.sort(function (a, b) {
							return new Date(a.Date) - new Date(b.Date);
						});
					}
					
				}
				setSensorList({ ...list });
				setChartData(sensorData);
				// if (data.rows && data.rows.length > 0) {
				// 	data.rows.sort(function (a, b) {
				// 		return new Date(a.Date) - new Date(b.Date);
				// 	});
				// 	let barChartData = data.rows.map((item) => {
				// 		return { date: dayjs(item.Date).format(dateFormat), Min: item.min, Max: item.max, Avg: item.avg };
				// 	});
				// 	const startCheck=barChartData.filter(ele=>{
				// 		return ele.date === parentFilters.Date.startDate
				// 	})
				// 	const endCheck=barChartData.filter(ele=>{
				// 		return ele.date === parentFilters.Date.endDate
				// 	})
				// 	if(startCheck.length === 0){
				// 		barChartData.unshift({date:parentFilters.Date.startDate})
				// 	}
				// 	if(endCheck.length === 0){
				// 		barChartData.push({date:parentFilters.Date.endDate})
				// 	}
				// 	setChartData({ ...chartData, data: barChartData, hospitals: data.hospitals.join(',') });
				// } else {
				// 	setChartData({ ...chartData, data: [], hospitals: '' });
				// }
			},
			modelConfig: { formDef: [] },
			parentFilters: {
				...parentF
			},
			action: 'report',
			extraParams: {...extraParams, isChartData: true}
		});
	}, [parentFilters]);

	return (
		<Container
			maxWidth="lg"
			sx={{
				mt: 5,
				mb: 4,
				backgroundColor: palette.primary.light_1,
				padding: '26px 20px 10px 0px'
			}}
		>
			<Filters
				setParentFilters={setParentFilters}
				parentFilters={parentFilters}
				setLabel={setLabel}
				label={label}
				showSensorTypeFilter={false}
				lookupParams={baseLookupParams}
				hospitalsFilterData={hospitalsFilterData}
				setHospitalsFilterData={setHospitalsFilterData}
				roomsFilterData={roomsFilterData}
				setRoomsFilterData={setRoomsFilterData}
				sensorFilterData={sensorFilterData}
				setSensorFilterData={setSensorFilterData}
			/>
			<Grid row>
				<Grid spacing={3} sx={{ mt: 3 }}>
					<Box className="only-print-content print-pd0" sx={{ p: 1 }}>
						<Box display="flex" justifyContent="center" alignItems="center" mb={1}>
							<img src={`data:image/png;base64,${userContext.appLogo2}`} style={{ width: 200, height: 40 }} alt={`${userContext?.docTitle}`} />
						</Box>
						<Box
							sx={{ fontWeight: 'bold', textDecoration: 'underline' }}
							mb={5}
							pt={2}
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							{' '}
							Humidity History Regulatory Report
						</Box>
						<Box className="report-header-container">
							<Box>Hospital: {printHospitalVal}</Box>
							<Box>
								Dates: {dayjs(parentFilters.Date.startDate).format(reportDurationDateFormat)} to{' '}
								{dayjs(parentFilters.Date.endDate).format(reportDurationDateFormat)}
							</Box>
							<Box>Created: {dayjs().format(reportCreatedDateFormat)}</Box>
						</Box>
					</Box>
					<Paper
						sx={{
							display: 'flex',
							flexDirection: 'column',
							backgroundColor: 'transparent',
							gap: '10px'
						}}
					>
						<Typography
							style={{
								backgroundColor: 'transparent',
								fontWeight: '300',
								color: palette.primary.text.dark
							}}
							component="h2"
							variant="h6"
							color="inherit"
							textAlign="left"
						>
							Humidity History Chart - {printHospitalVal?printHospitalVal:'All'}
						</Typography>
						{sensorList &&
							Object.keys(sensorList).length > 0 &&
							Object.keys(sensorList).map((sensor, i) => {
								let { sensorId, sensorName } = sensorList[sensor];
								return (
									<MultiLineChart
										data={chartData?.[sensorId] ?? []}
										sensorInfo={sensorList[sensor]}
										isDuration={false}
										yAxisLabel1="Humidity"
										unit={'%'}
									/>
								);
							})}
					</Paper>
				</Grid>
			</Grid>
			<Grid row>
				<Grid spacing={3} sx={{ mt: 7 }}>
					<FormBase
						modelConfig={humidityHistory}
						key={humidityHistory.title}
						parentFilters={parentFilters}
						action={'report'}
						extraParams={extraParams}
						title={`${props.title} - ${printHospitalVal?printHospitalVal:'All'}`}
					/>
				</Grid>
			</Grid>
		</Container>
	);
}

export default HumidityHistory;
