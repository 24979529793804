const config = {
    title: 'Alert',
    controllerType: 'elastic',
    defaultSort: 'Date desc',
    isClient: true,
    module: 'dashboard',
    linkColumn: 'recipientEmail',
    columns: [
        { field: "sensorId", width: 250, header: "Sensor", required: true, hideable: false },
        { field: "SensorId", width: 150, header: "Sensor Code" },
        { field: "Description", width: 150, header: "Alert" },
        { field: "roomName", width: 150, header: "Room" },
        { field: "Date", width: 200, header: "Alert Time", type: "date", isUtc: true },
        { field: "recipientEmail", flex: 1, header: "Recipient Email", type: "string", filterable: false },
        { field: "sentOn", flex: 1, header: "Sent On", type: "date", filterable: false },
        { field: "escalationLevel", flex: 1, header: "Escalation Level", filterable: false, width: 100 }
    ],
    parentRelation: 'alertDefinitionSensorId'
};

export default config;
