const config = {
	title: 'Humidity Daily Summary',
	name: 'humidityDailySummary',
	defaultSort: 'Date desc',
	api: 'telemetry/summary',
	controllerType: 'elastic',
	readOnly: true,
	module: 'humidity',
	columns: [
		{
			field: 'sensorId',
			flex: 1,
			header: 'Sensor',
			lookup: 'sensor',
			required: true,
			hideable: false,
			elasticFilter: false,
			sortable: false,
			filterable: false
		},
		{
			field: 'sensorCode',
			flex: 1,
			header: 'Sensor Code',
			type: 'string',
			readOnly: true,
			sortable: false,
			filterable: false
		},
		{
			field: 'Date',
			flex: 1,
			header: 'Date',
			type: 'date',
			isLocal: true,
			time: false,
			elasticFilter: false,
			showDateOnly: true,
		},
		{ field: 'avg', flex: 1, header: 'Avg (%)', type: 'number' },
		{ field: 'duration', flex: 1, header: 'Duration OOR', type: 'string', sortable: false, filterable: false },
		{ field: 'min', flex: 1, header: 'Min (%)', type: 'number' },
		{ field: 'max', flex: 1, header: 'Max (%)', type: 'number' }
	]
};

export default config;
