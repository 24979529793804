import { React } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import FormControl from '@mui/material/FormControl';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { useTheme } from '@mui/styles';

const CustomDateRangeInputs = (props) => {
	const { value, setDateTimeRangeValue } = props;
	const { palette } = useTheme();
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DateRangePicker
				InputProps={{
					disableUnderline: true
				}}
				label="Advanced keyboard"
				maxDate={dayjs()}
				value={value}
				onChange={(newValue) => setDateTimeRangeValue(newValue)}
				renderInput={({ inputProps, ...startProps }, endProps) => {
					const startValue = inputProps.value;
					delete inputProps.value;
					startProps.label = 'Date Range';
					return (
						<FormControl size="small" fullWidth variant="standard">
							<TextField
								sx={{
									color: palette.primary.text.dark,
									'& .MuiSvgIcon-root': {
										marginLeft: 1
									},
									svg: { color: palette.primary.text.dark },
									input: { color: palette.primary.text.dark },
									label: { color: palette.primary.text.dark, opacity: 0.5, marginLeft: -1.6 },
									fontSize: 18,
									'& .MuiInputLabel-root': {
										padding: '0px'
									}, //styles the label
									'& .MuiOutlinedInput-root': {
										'& > fieldset': {
											border: '1px solid transparent',
											borderBottom: `1px solid ${palette.primary.text.medium}`
										}
									},
									'&:hover fieldset': {
										border: 'none',
										borderBottom: `1px solid ${palette.primary.text.medium}`
									}
								}}
								{...startProps}
								inputProps={inputProps}
								InputProps={{
									endAdornment: <CalendarTodayIcon fontSize="small" />
								}}
								value={`${startValue}  -  ${endProps.inputProps.value}`}
							/>
						</FormControl>
					);
				}}
				PopperProps={{
					sx: {
						'& .MuiPaper-root': {
							backgroundColor: palette.primary.light_3,
							color: palette.primary.text.dark,
							fontWeight: '300'
						},
						'& .MuiTypography-root': {
							color: palette.primary.text.dark
						},
						'& .MuiDateRangePickerDay-notSelectedDate': {
							color: palette.primary.text.dark
						},
						'& .MuiDateRangePickerDay-day': {
							color: palette.primary.text.dark
						},
						'& .MuiPickersDay-root': {
							color: palette.primary.text.dark
						},
						'& .MuiButtonBase-root': {
							color: palette.primary.text.dark
						},
						'& .Mui-disabled': {
							color: palette.primary.text.light
						}
					}
				}}
			/>
		</LocalizationProvider>
	);
};

export default CustomDateRangeInputs;
