const config = {
    title: 'Sensor Type',
    defaultSort: 'label',
    module: 'sensorType',
    columns: [
        { field: "profileId", flex: 1, header: "Profile Id", type: "number", minValue: 1, maxValue: 10000, maxWidth: 120 },
        { field: "label", flex: 1, header: "Sensor Type", required: true, minLength: 2, maxLength: 50, maxWidth: 350, hideable: false },
        { field: "unit", flex: 1, header: "Unit of measurement", minLength: 1, maxLength: 50 },
        { field: "isDuration", type: "boolean", header: "Duration?" }
    ],
    relations: [
        'sensor'
    ]
};

export default config;