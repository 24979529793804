import { Oval } from 'react-loader-spinner';

const useLoader = true;

const Loader = ({ isLoading }) => {
    return <div className={"loaderContainer"} style={{ display: (!useLoader || !isLoading) ? "none" : "" }}>
        <Oval color="#00BFFF" height={80} width={80} />
    </div>
};

export default Loader;