import React from 'react';
import dayjs from 'dayjs';
import {
	Bar,
	ResponsiveContainer,
	ComposedChart,
	Line,
	Label,
	XAxis,
	YAxis,
	CartesianGrid,
	Legend,
	Tooltip
} from 'recharts';
import { useTheme } from '@mui/styles';
import CustomizedRightTick from './CustomizedRightTick';

const LineChartColors = ['#faec2a', '#00EEBB', '#bada55', '#ee6cff', '#ff8018', '#38f100'];

const CustomizedTick = (props) => {
	const { x, y, payload, fill } = props;
	let formattedDate = dayjs.utc(payload?.value).format('YYYY-MM-DD');
	return (
		<g transform={`translate(${x},${y})`}>
			<text x={0} y={0} dy={16} textAnchor="end" fill={fill}>
				{formattedDate}
			</text>
		</g>
	);
};

const CustomTooltip = ({ active, payload, palette }) => {
	if (active && payload && payload.length) {
		const { Date : payloadDate, Data, max, min, sensorId } = payload[0].payload;
		return (
			<div
				style={{
					backgroundColor: palette.primary.medium,
					border: `1px solid ${palette.primary.medium}`,
					color: palette.primary.text.dark,
					boxShadow: `0px 0px 2px ${palette.primary.text.dark}`,
					padding: '7px 20px 7px 4px'
				}}
			>
				<div style={{ color: palette.primary.text.dark }}>
					{sensorId && <p>{sensorId}</p>}
					<p>{dayjs.utc(payloadDate).format('MM-DD-YYYY HH:mm:ss')}</p>
					{Data && <p>Reading: {Data}</p>}
					{min && <p>Min: {min}</p>}
					{max && <p>Max: {max}</p>}
				</div>
			</div>
		);
	}

	return null;
};

const MultiLineChart = ({ data, sensorInfo, isDuration, yAxisLabel1 = 'Temperature', unit }) => {
	const { palette } = useTheme();

	const labelName = isDuration ? `Open Count` : `${yAxisLabel1} (in ${unit})`;
	const barDataKey = isDuration ? 'Duration' : 'Range';
	const labelY1 = { value: labelName, angle: -90, position: 'inside', fontSize: 12 };
	const labelY2 = { value: `Duration (in ${`hh mm ss`})`, angle: -90, position: 'outside', fontSize: 12 };

	let { sensorId, sensorName } = sensorInfo;
	return (
		<>
			<ResponsiveContainer width="100%" height={320}>
				<ComposedChart
					style={{
						backgroundColor: palette.primary.light_1,
						marginBottom: 30,
						fontSize: '12px'
					}}
					height={300}
					data={data ?? []}
				>
					<CartesianGrid strokeDasharray="1 1" />
					<XAxis
						dataKey="Date"
						domain={['auto', 'auto']}
						allowDataOverflow={false}
						tick={<CustomizedTick fill={palette.primary.text.dark} />}
					/>
					<YAxis
						domain={['auto', 'auto']}
						width={100}
						allowDataOverflow={false}
						yAxisId="leftY"
						tick={{ fill: palette.primary.text.dark }}
					>
						<Label
							value={labelY1.value}
							angle={-90}
							style={{
								textAnchor: 'middle',
								fill: palette.primary.text.dark
							}}
						/>
					</YAxis>
					{isDuration && (
						<YAxis
							yAxisId={'rightY'}
							orientation="right"
							domain={['auto', 'auto']}
							width={220}
							tick={<CustomizedRightTick fill={palette.primary.text.dark}/>}
						>
							<Label
								value={labelY2.value}
								angle={90}
								style={{
									textAnchor: 'middle',
									fill: palette.primary.text.dark
								}}
							/>
						</YAxis>
					)}
					<Tooltip content={<CustomTooltip payload={data} palette={palette} />} />
					<Legend />
					{isDuration ? (
						<>
							<Bar yAxisId={'leftY'} dataKey={'Count'} fill={palette.primary.gridLines} />
							<Line
								yAxisId={'rightY'}
								key={sensorId}
								name={sensorName}
								dataKey={barDataKey}
								stroke={palette.primary.gridLines}
							/>
						</>
					) : (
						<>
							<Line
								key={sensorId}
								dataKey={`Data`}
								dot={false}
								stroke={'#bada55'}
								connectNulls={true}
								name={sensorName}
								type="monotone"
								yAxisId="leftY"
							/>

							<Line
								dataKey={`min`}
								yAxisId="leftY"
								stroke="#60E080"
								dot={false}
								connectNulls={true}
								type="monotone"
							/>
							<Line
								dataKey={`max`}
								yAxisId="leftY"
								stroke="red"
								dot={false}
								connectNulls={true}
								type="monotone"
							/>
						</>
					)}
				</ComposedChart>
			</ResponsiveContainer>
		</>
	);
};

export default MultiLineChart;
