const config = {
	title: 'Data Filter',
	name: 'dataFilter',
	defaultSort: 'createdAt desc',
	linkColumn: 'userId',
	module: 'dataFilter',
	columns: [
		{ field: 'userId', width: 200, header: 'User', lookup: 'user' },
		{ field: 'pathname', width: 150, header: 'Path Name' },
		{
			field: 'filter',
			width: 200,
			flex: 1,
			header: 'Filter',
			type: 'json',
			hideConfig: {
				onHide: () => {
					return { isHide: true };
				}
			}
		}
	],
	relations: ['user']
};

export default config;
