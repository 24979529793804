const config = {
    title: 'Role',
    defaultSort: 'label',
    module: 'role',
    columns: [
        { field: "label", flex: 1, header: "Role", required: true, minLength: 2, maxLength: 50, unique: true, maxWidth: 130, hideable: false },
        { field: 'description', flex: 1, header: 'Description', maxLength: 60 },
    ],
    relations: [
        'user',
        'roleModule',
    ],
    setAuditRelations: false
};

export default config;