import { toast } from 'react-toastify';

class Logger {
    static log(message, error, { show = false, prefix = null } = {}) {
        if (show) {
            toast(`${prefix ? `${prefix}: ` : ''}${message}`);
        }
        console.log(message);
        if (error) {
            console.error(error);
        }
    }
    static error(message, error) {
        Logger.log(message, error, { show: true, prefix: 'Error' });
    }
    static warn(message, error) {
        Logger.log(message, error, { show: true, prefix: 'Warning' });
    }
    static info(message, error) {
        Logger.log(message, error, { show: true, prefix: 'Info' });
    }
}

export default Logger;