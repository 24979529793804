const config = {
    title: 'Telemetry',
    defaultSort: 'Date desc',
    controllerType: 'elastic',
    readOnly: true,
    module: 'telemetry',
    securityFilter: {
        hospitalId: ""
    },
    columns: [
        { field: "sensorId", flex: 1, header: "Sensor Name", required: true, minLength: 2, maxLength: 10, hideable: false },
        { field: "SensorId", flex: 1, header: "Sensor Code", hideable: false },
        { field: "hubId", flex: 1, header: "Hub Id" },
        { field: "roomId", flex: 1, header: "Room Id" },
        { field: "Date", flex: 1, header: "Date", type: "date", isLocal: false, isUtc: true, time: true, },
        { field: "Data", header: "Reading", type: "number", decimal: true, width: 120 },
        { field: "State", header: "State", type: "number", decimal: true, width: 120 },
        { field: "InRange", width: 100, header: "In Range", type: "boolean" },
        { field: "Description", flex: 1, header: "Description", maxLength: 60 }
    ],
    presets: [
        {
            "title": "Out of range",
            "filter": {
                "InRange": false
            }
        }
    ]
};

export default config;
