const config = {
    title: 'Hospital',
    displayField: 'name',
    defaultSort: 'code',
    module: 'hospital',
    securityFilter: {
        hospitalId: "hospital"
    },
    columns: [
        { field: "code", flex: 1, header: "Code", required: true, minLength: 2, maxLength: 10, hideable: false },
        { field: "providerId", flex: 1, header: "Provider Id", maxLength: 10 },
        { field: "name", flex: 1, header: "Name", required: true, minLength: 2, maxLength: 50 },
        { field: "description", flex: 1, header: "Description", maxLength: 60 },
        { field: "isActive", header: "Active", type: 'boolean', defaultValue: true, width: 80 },
        { field: "url", width: 230, header: "Hospital Url", maxLength: 100 }
    ],
    relations: [
        'room',
        'mqttClient',
        'user',
        'telemetry',
        'telemetrySummary',
    ]
};

export default config;
