import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({ title, body, open, cancelButtonText = "Cancel", okButtonText = "Ok", onCancel, onConfirm }) {

    const handleClose = (event) => {
        const { id } = event.currentTarget.dataset;
        if (id === 'ok') {
            onConfirm();
        } else if (onCancel) {
            onCancel();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title || "Alert"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {body}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button data-id="cancel" onClick={handleClose}>{cancelButtonText}</Button>
                <Button data-id="ok" onClick={handleClose} autoFocus>{okButtonText}</Button>
            </DialogActions>
        </Dialog>
    );
}