const config = {
    title: 'My Alerts History',
    defaultSort: 'Date desc',
    api: 'telemetry/summary',
    controllerType: 'elastic',
    readOnly: true,
    linkColumn: 'recipientEmail',
    module: 'alertRecipient',
    columns: [
        { field: "sensorId", width: 250, header: "Sensor", type: 'string', required: true, hideable: false, sortable: false },
        { field: "sensorCode", width: 120, header: "Sensor Code", type: 'string', readOnly: true, sortable: false },
        { field: "Description", width: 200, header: "Alert", type: 'string', readOnly: true, sortable: false, filterable: false },
        { field: "roomName", width: 100, header: "Room", type: 'string', readOnly: true, sortable: false, filterable: false },
        { field: "Date", width: 200, header: "Alert Time", type: "date", showDateOnly: false, isUtc: true, isLocal: false },
        {
            field: "acknowledged", width: 120, header: "Alert State", headerAlign: 'left', align: 'left', type: "boolean", sortable: false, filterable: false, renderCell: (params) => {
                let toReturn = 'active';
                if (!params.row?.isOpen && params.row?.acknowledged) {
                    toReturn = 'acknowledged';
                }
                else if (!params.row?.isOpen ) {
                    toReturn = 'resolved';
                }
                return toReturn
            },
            hideConfig: {
                onHide: () => { return { isHide: true } }
            }
        },
        { field: "acknowledgedDateTime", width: 220, header: "Acknowledged Timestamp", type: "date", showDateOnly: false, isUtc: true, isLocal: false, sortable: false, filterable: false },
        { field: "comment", width: 100, header: "Comment", type: 'string', readOnly: true, sortable: false, filterable: false },
        { field: "commentDateTime", width: 220, header: "Comment Timestamp", type: "date", showDateOnly: false, isUtc: true, isLocal: false, sortable: false, filterable: false },
        {
            field: "Data", width: 100, header: "Reading", type: 'number', readOnly: true, renderCell: (params) => {
                let toReturn = "";
                switch (params.row?.ProfileId) {
                    case 9:
                        toReturn = params.row?.State === 2 ? "Closed" : "Open"
                        break;
                    case 1:
                        toReturn = `${params.row?.Data} ${params.row?.unit}`;
                        break;
                    case 2:
                        toReturn = `${params.row?.Data} \u00B0C`;
                        break;
                    default:
                        toReturn = params.row?.Data
                        break;
                }
                return toReturn
            },
            hideConfig: {
                onHide: () => { return { isHide: true } }
            }
        },
        { field: "min", width: 100, header: "Min", type: "number" },
        { field: "max", width: 100, header: "Max", type: "number" },
        { field: "recipientEmail", width: 220, header: "Recipient Email", type: "string", sortable: false, filterable: false },
        { field: "sentOn", width: 200, header: "Sent On", type: "date", time: true, showDateOnly: false }
    ],
    parentRelation: 'alertDefinitionSensorId'
};

export default config;
