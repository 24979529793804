const config = {
    title: 'Alert Definition Sensor',
    defaultSort: 'alertDefinitionId desc',
    linkColumn: 'sensorId',
    module: 'alertDefinitionSensor',
    columns: [
        { field: "alertDefinitionId", header: "Alert Definition Id", lookup: "alertDefinition", required: true, flex: 1 },
        { field: "sensorId", header: "Sensor", required: true, width: 200, lookup: 'sensor', flex: 1 }
    ],
    parentRelation: 'sensorTypeId',
    relations: [
        'notifications'
    ]
};

export default config;
