
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import React from "react";
import { useTheme } from "@mui/styles";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { UserProvider } from './contexts/userContext';

import { Box } from '@mui/material';

import FormBase from './views/form-base';
import models from './config/models';
import Dashboard from './views/Dashboard';
import Layout from './Layout';
import Report from './views/Report';
import NotFound from './views/NotFound';
import TemperatureHistoryReport from './views/TemperatureHistory';
import TemperatureDailySummaryReport from './views/TemperatureDailySummary';

import DoorMovementSummary from './views/DoorMovementSummary';
import DoorMovementHistory from './views/DoorMovementHistory';
import SystemHealth from './views/SystemHealth';
import Login from './views/Login';
import MyAlertsHistory from './views/MyAlertsHistory';
import HumidityDailySummary from './views/HumidityDailySummary';
import DoorMovementAverage from './views/DoorMovementAverage';
import HumidityHistory from './views/HumidityHistory';
import AlertsHistory from './views/AlertsHistory';

const App = () => {
	const { palette } = useTheme();
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<UserProvider>
				<Router>
					<Routes>
						<Route exact path="/sign-in" element={<Login />} />
						<Route path="/" element={<Layout />}>
							<Route path="/" element={<Dashboard />} />
							{models.map((model) => (
								<Route
									path={model.route}
									key={model.title}
									element={
										<Box
											sx={{
												padding: '26px 20px 10px 20px',
												backgroundColor: palette.primary.light_1
											}}
										>
											<FormBase key={model.title} modelConfig={model} />
										</Box>
									}
								/>
							))}
							<Route path="Report" element={<Report />} />
							<Route
								path="temperature-history"
								element={<TemperatureHistoryReport title={'Temperature History'} />}
							/>
							<Route
								path="temperature-summary"
								element={<TemperatureDailySummaryReport title={'Temperature Daily Summary'} />}
							/>
							<Route
								path="door-movement-summary"
								element={<DoorMovementSummary title={'Door Movement Summary'} />}
							/>
							<Route path="door-movement-history" element={<DoorMovementHistory />} />
							<Route path="door-movement-average" element={<DoorMovementAverage />} />
							<Route path="my-alerts-history" element={<MyAlertsHistory title={'My Alerts History'} />} />
							<Route path="alerts-history" element={<AlertsHistory title={'Alerts History'} />} />
							<Route path="system-health" element={<SystemHealth />} />
							<Route
								path="humidity-summary"
								element={<HumidityDailySummary title={'Humidity Daily Summary'} />}
							/>
							<Route path="humidity-history" element={<HumidityHistory title={'Humidity History'} />} />
							<Route path="*" element={<NotFound />} />
						</Route>
						<Route path="*" element={<NotFound />} />
					</Routes>
				</Router>
			</UserProvider>
		</LocalizationProvider>
	);
};

export default App;
