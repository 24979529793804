const config = {
	title: 'Temperature History Report',
	name: 'temperatureHistory',
	defaultSort: 'Date desc',
	api: 'telemetry/summary',
	controllerType: 'elastic',
	readOnly: true,
	module: 'temperature',
	columns: [
		{
			field: 'sensorId',
			flex: 1,
			header: 'Sensor',
			lookup: 'sensor',
			required: true,
			hideable: false,
			elasticFilter: false,
			sortable: false,
			filterable: false
		},
		{
			field: 'sensorCode',
			header: 'Sensor Code',
			type: 'string',
			readOnly: true,
			elasticFilter: false,
			sortable: false,
			filterable: false,
			width: 120
		},
		{ field: 'Data', width: 120, header: 'Reading (C\u00B0)', type: 'number', elasticFilter: false, filterable: false },
		{
			field: 'Date',
			header: 'Date/time stamp',
			type: 'date',
			isLocal: false,
			isUtc: true,
			elasticFilter: false,
			showDateOnly: false,
			time: true,
			flex: 1
		},
		// { field: 'min', flex: 1, header: 'Min (C\u00B0)', type: 'number', elasticFilter: false, filterable: false },
		// { field: 'max', flex: 1, header: 'Max (C\u00B0)', type: 'number', elasticFilter: false, filterable: false }
	]
};

export default config;
