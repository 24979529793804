import { useState, useCallback, useEffect, useMemo } from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/styles';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useSearchParams } from 'react-router-dom';

import FormDialog from '../Components/ModalForm/ModalForm';
import GridBase from '../Components/Grid/index.js';
import models from '../config/models';

const Relations = ({ relations, parentFilters, parent, where }) => {
	const [activeTab, setActiveTab] = useState(relations?.length ? relations[0] : null);
	const { palette } = useTheme();

	if (!relations?.length || !parentFilters) return null;

	const handleChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	return (
		<>
			<TabContext value={activeTab}>
				<Box>
					<TabList
						sx={{
							'& .css-1mhpt05-MuiButtonBase-root-MuiTab-root': {
								color: palette.primary.text.dark
							},
							'.Mui-selected': {
								color: `${palette.primary.selected_tab} !important`
							},
							'.MuiButtonBase-root':{
								color: `${palette.primary.unselected_tab}`
							}
						}}
						onChange={handleChange}
					>
						{relations.map((relation) => (
							<Tab
								key={relation}
								label={models.find((model) => model.name === relation)?.listTitle}
								value={relation}
								sx={{
									color: palette.primary.text.medium
								}}
							/>
						))}
					</TabList>
				</Box>
				{relations.map((relation) => (
					<TabPanel sx={{ padding: 0 }} value={relation} key={relation}>
						<FormBase
							modelConfig={models.find((model) => model.name === relation)}
							parentFilters={parentFilters}
							parent={parent}
							where={where}
						/>
					</TabPanel>
				))}
			</TabContext>
		</>
	);
};

const FormBase = ({
	modelConfig,
	parentFilters,
	parent,
	action,
	report,
	isForDashboard = false,
	extraParams,
	where,
	title
}) => {
	const [activeRecord, setActiveRecord] = useState(undefined);
	const [childFilters, setChildFilters] = useState(null);
	const [refresh, setRefresh] = useState(null);
	const [lookupWhere, setLookupWhere] = useState({});
	const [searchParams, setSearchParams] = useSearchParams({});
	const searchParamsFilters = Object.entries(Object.fromEntries(searchParams)).reduce((acc, [key, value]) => {
		acc[key] = JSON.parse(value);
		return acc;
	}, {});
	const onSetActiveRecord = (record, { refresh = true } = {}) => {
		setActiveRecord(record);
		if (!refresh) return;
		setRefresh(new Date());
	};

	const onSelection = useCallback(
		(id, rec) => {
			setChildFilters(id ? { [modelConfig.name + 'Id']: id } : null);
			if (id && rec) {
				if (modelConfig && modelConfig.relations) {
					const relation = modelConfig.relations[0];
					//TODO should be done dynamic instead of 0 index
					if (relation) {
						const relationModel = models.find((a) => a.name === relation);
						if (relationModel && relationModel.parentRelation) {
							setLookupWhere({ [relationModel.parentRelation]: rec[relationModel.parentRelation] });
						}
					}
				}
			}
		},
		[modelConfig]
	);

	useEffect(() => {
		onSelection(0);
	}, [parentFilters, onSelection]);

	return (
		<>
			{activeRecord === undefined || modelConfig.readOnly === true || isForDashboard ? null : (
				<FormDialog
					modelConfig={modelConfig}
					activeRecord={activeRecord}
					parent={parent}
					setActiveRecord={onSetActiveRecord}
				/>
			)}
			<GridBase
				title={title}
				modelConfig={modelConfig}
				setActiveRecord={setActiveRecord}
				onSelection={onSelection}
				parentFilters={{ ...parentFilters, ...searchParamsFilters }}
				parent={parent}
				refresh={refresh}
				action={action}
				report={report}
				isForDashboard={isForDashboard}
				extraParams={extraParams}
				where={where}
				baseFilters={{}}
				readNotification={modelConfig.readNotification}
			/>
			{isForDashboard ? null : (
				<Relations
					relations={modelConfig.relations}
					parentFilters={childFilters}
					parent={modelConfig.name}
					where={lookupWhere}
				/>
			)}
		</>
	);
};
export default FormBase;
