import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { roleModules } from 'dips-models';
import { useTheme } from '@mui/styles';
import { List, ListItemButton, ListItemIcon, Collapse, ListSubheader, ListItemText, Box, ListItem } from '@mui/material';

import models from '../config/models';
import { UserContext } from '../contexts/userContext';
import {
	DashboardIcon,
	ReportsIcon,
	DoorIcon,
	SettingsIcon,
	TemperatureIcon,
	Alert_definitionsIcon,
	humidityIcon
} from '../assets/icons';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined'; //TODO: Create a unique illustrative System Health S
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { flexbox } from '@mui/system';
import util from '../util';

const modelToMenuItem = (model) => {
	return {
		title: model.listTitle,
		link: `/${model.route}`,
		icon: model.icon,
		module: model.module
	};
};

const menus = (modules) => {
	const setupMenuTab = models.filter((e) => e.icon && e.parentKey === 'Setup');
	const setupMenu = setupMenuTab.map((e) => modules.hasOwnProperty(e.module) && modelToMenuItem(e)).filter(Boolean);
	const systemHealthMenuTab = models.filter((e) => e.parentKey === 'System Health');
	const systemHealthMenu = systemHealthMenuTab
		.map((e) => modules.hasOwnProperty(e.module) && modelToMenuItem(e))
		.filter(Boolean);

	const menuItems = [
		{ title: 'Dashboard', icon: DashboardIcon, link: '/', isOpen: false, module: 'dashboard' },
		{
			title: 'Reports',
			icon: ReportsIcon,
			sectionName: 'reports',
			children: [
				{
					title: 'Report History',
					link: `/report`,
					icon: ReportsIcon
				},
				{
					title: 'Temperature',
					icon: TemperatureIcon,
					sectionName: 'temperature',
					children: [
						{
							title: 'Temperature History',
							link: `/temperature-history`
						},
						{
							title: 'Temperature Daily Summary',
							link: `/temperature-summary`
						}
					]
				},
				{
					title: 'Humidity',
					icon: humidityIcon,
					sectionName: 'humidity',
					children: [
						{
							title: 'Humidity History',
							link: `/humidity-history`
						},
						{
							title: 'Humidity Daily Summary',
							link: `/humidity-summary`
						}
					]
				},
				{
					title: 'Door Movement',
					icon: DoorIcon,
					sectionName: 'door-movement',
					children: [
						{
							title: 'Door Movement Activity History',
							link: `/door-movement-history`
						},
						{
							title: 'Door Movement Count Summary',
							link: `/door-movement-summary`
						},
						{
							title: 'Average Door Movement',
							link: `/door-movement-average`
						}
					]
				},
				{
					title: 'Alerts',
					icon: Alert_definitionsIcon,
					sectionName: 'alerts',
					children: [
						{
							title: 'Alerts History',
							link: `/alerts-history`
						},
						{
							title: 'My Alert History',
							link: `/my-alerts-history`
						}
					]
				}
			],
			isOpen: false,
			module: 'report'
		}
	];
	let menuModels = [];
	const alertDefinationModel = models.filter(
		(model) => model.name === 'alertDefinition' && modules.hasOwnProperty(model.module)
	)[0];
	const caseModel = models.filter((model) => model.name === 'case' && modules.hasOwnProperty(model.module))[0];
	const sensorModel = models.filter(
		(model) => model.name === 'sensor' && !Boolean(model.parentKey) && modules.hasOwnProperty(model.module)
	)[0];
	const roomModel = models.filter((model) => model.name === 'room' && modules.hasOwnProperty(model.module))[0];

	if (!!alertDefinationModel) {
		menuItems.push(modelToMenuItem(alertDefinationModel));
	}
	if (!!caseModel) {
		menuItems.push(modelToMenuItem(caseModel));
	}
	if (modules.hasOwnProperty('setup')) {
		menuItems.push({ title: 'Setup', module: 'setup', icon: SettingsIcon, children: setupMenu, isOpen: false });
	}
	if (!!sensorModel) {
		menuItems.push(modelToMenuItem(sensorModel));
	}
	if (!!roomModel) {
		menuItems.push(modelToMenuItem(roomModel));
	}
	/* if (setupMenu.length > 0) {
        // menuItems.push({ title: 'Setup', module: 'setup', icon: SettingsIcon, children: setupMenu, isOpen: false });
    }
    // models.sort((a, b) => a.listTitle.localeCompare(b.listTitle)).forEach(model => !model.parentKey && model.icon && modules.hasOwnProperty(model.module) && menuItems.push(modelToMenuItem(model))); */
	if (systemHealthMenu.length > 0) {
		menuItems.push({
			title: 'System Health',
			module: 'systemHealth',
			icon: MonitorHeartOutlinedIcon,
			children: [
				...systemHealthMenu,

				{
					title: 'Health',
					link: `/system-health`,
					icon: MonitorHeartOutlinedIcon
				}
			],
			isOpen: false
		});
	}
	return menuItems;
};

const SideList = ({ Icon, title, handleClick, canExpand, open, link, sx, currentItem, isMenuOpen }) => {
	const { palette } = useTheme();

	return (
		<ListItemButton
			onClick={typeof handleClick === 'function' ? () => handleClick(open, title) : null}
			sx={[
				{
					color: palette.primary.text.dark,
					fontWeight: '300',
					fontSize: 15,
					alignItems: 'center',
					backgroundColor: link === window.location.pathname ? palette.primary.light_4 : 'transparent',
					...sx,
					'&:hover': {
						backgroundColor: palette.primary.light_4
					}
				}
			]}
		>
			{!!Icon && (
				<ListItemIcon
					sx={{
						color: palette.primary.text.dark
					}}
				>
					<Icon color={palette.primary.text.dark} />
				</ListItemIcon>
			)}
			<ListItemText>{title}</ListItemText>

			{canExpand && isMenuOpen && (
				<ListItemIcon>
					<ChevronRightIcon
						style={{
							transition: 'all 0.4s ease',
							...(open
								? {
										transform: 'rotate(90deg)'
								  }
								: {
										transform: 'rotate(0deg)'
								  }),
							color: palette.primary.text.dark
						}}
					/>
				</ListItemIcon>
			)}
		</ListItemButton>
	);
};



const MainListItems = ({ isMenuOpen }) => {
	const [userContext] = React.useContext(UserContext);
	const { palette } = useTheme();
	const modules = roleModules[userContext?.role?.replace(/ /g, '').toLowerCase()];
	let menuItems = modules ? menus(modules) : [];
	menuItems = menuItems.filter((menu) => modules.hasOwnProperty(menu.module) && menu);
	const [menuProps, setMenuProps] = React.useState(menuItems);

	const toggleSubSection = (sectionName, childSectionName) => (e) => {
		e.preventDefault();
		e.stopPropagation();
		setMenuProps(
			menuItems.map((m) => {
				if (m.sectionName === sectionName && m.children?.length > 0) {
					const newM = {
						...m,
						isOpen:
							!m.isOpen || m.children.some((child) => child.sectionName === childSectionName && !child.isOpen),
						children: m.children.map((child) => ({
							...child,
							isOpen: m.isOpen ? false : child.sectionName === childSectionName
						}))
					};
					return newM;
				}
				return m;
			})
		);
	};

	const handleClick = (prev, title) => {
		let currentItemIndex = menuItems.findIndex((item) => item.title === title);
		if (currentItemIndex > -1) {
			menuItems[currentItemIndex].isOpen = !prev;
			setMenuProps([...menuItems]);
		}
	};

    const MultiLevel = ({ item, index, title, mainChildren }) => {
    
        const children = item.children;
        
        const Icon = item.icon;
        const [open, setOpen] = useState(false);
        let itemObj = item;
    
        const { palette } = useTheme();
    
        const handleClick = (clickedTitle) => {
            setOpen((prev) => !prev);
            let currentItemIndex = menuItems.findIndex((item) => item.title === title);

            let childrenItems = []

            mainChildren.forEach((c) => {
                if(c.title === clickedTitle){
                    let obj = {...c, isChildOpen: !c.isChildOpen}
                    childrenItems.push(obj)
                    return obj;
                } else {
                    let obj = {...c, isChildOpen: false}
                    childrenItems.push(obj)
                    return obj;
                }
            })

            let menus = []

            menuItems.forEach((it) => {
                if(it.title === title){
                    let obj = {...it, isOpen: true, children: childrenItems}
                    menus.push(obj);
                } else {
                    let obj = {...it, isOpen: false}
                    menus.push(obj);
                }
            })

            if (currentItemIndex > -1) {
                menuItems[currentItemIndex].children = childrenItems;
                menuItems[currentItemIndex].isOpen = !open;
                setMenuProps([...menus]);
            }
            itemObj = {...item, isChildOpen: !open}
            
        };
    
        return (
            <>
                <List
                    sx={{
                        pl: 2,
                        '& .MuiListSubheader-root': {
                            backgroundColor: 'transparent',
                            color: palette.primary.text.dark
                        }
                    }}
                    component="div"
                    key={`section-${item.title}`}
                    disablePadding
                >
                    <ListItem button onClick={() => handleClick(item.title)}>
                        <ListItemIcon sx={{ color: palette.primary.text.dark }}>
                            <Icon color={palette.primary.text.dark} />
                        </ListItemIcon>
                        {/* </ListSubheader> */}
                        <Box sx={{ flexGrow: 1 }}>{item.title}</Box>
                        {open || item.isChildOpen  ? <KeyboardArrowDownOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
                    </ListItem>
                </List>
                <Collapse in={open || item.isChildOpen} timeout="auto" unmountOnExit>
                    <List sx={{ pl: 9 }} component="div" key={item.title} disablePadding>
                        {children.map((child, key) => (
                            <NavLink className="menu-link" key={child.title} to={child.link}>
                                <SideList link={child.link} title={child.title} sx={{ pl: 2, pr: 4 }} />
                            </NavLink>
                        ))}
                    </List>
                </Collapse>
            </>
        );
    };
    
    const SingleLevel = ({ item }) => {
        return (
            <NavLink className="menu-link" key={item.title} to={item.link}>
                <SideList link={item.link} open={true} title={item.title} Icon={item.icon} sx={{ pl: 4 }} isMenuOpen={true} />
            </NavLink>
        );
    };
    
    const MenuItem = ({ index, item, mainTitle, children }) => {
        const Component = util.hasChildren(item) ? MultiLevel : SingleLevel;
        return <Component item={item} index={index} title={mainTitle} mainChildren={children} />;
    };

	return (
		<>
			{menuProps.map(({ title, icon: Icon, link, children, isOpen, sectionName }) => {
				const hasChildren = children && children.length;
				return (
					<>
						{!hasChildren && (
							<NavLink disable className="menu-link" key={title} to={link}>
								<SideList title={title} Icon={Icon} link={link} isMenuOpen={isMenuOpen} />
							</NavLink>
						)}
						{hasChildren && (
							<SideList
								title={title}
								Icon={Icon}
								handleClick={handleClick}
								canExpand={hasChildren}
								open={isOpen}
								currentItem={title}
								link={link}
								isMenuOpen={isMenuOpen}
							/>
						)}

						{hasChildren && (
							<Collapse in={isMenuOpen && isOpen} timeout="auto" unmountOnExit key={title}>
								<List component="div" disablePadding>
									{children.map((child, i) => {
										return <MenuItem key={i} item={child} index={i} mainTitle={title} children={children} />;
									})}
								</List>
							</Collapse>
						)}
					</>
				);
			})}
		</>
	);
};

export { MainListItems };
