const config = {
	title: 'Humidity History',
	name: 'humidityHistory',
	defaultSort: 'Date desc',
	api: 'telemetry/summary',
	controllerType: 'elastic',
	readOnly: true,
	module: 'humidity',
	columns: [
		{
			field: 'sensorId',
			flex: 1,
			header: 'Sensor',
			lookup: 'sensor',
			required: true,
			hideable: false,
			sortable: false,
			filterable: false
		},
		{
			field: 'sensorCode',
			width: 120,
			header: 'Sensor Code',
			lookup: 'sensor',
			required: true,
			hideable: false,
			sortable: false,
			filterable: false
		},
		{ field: 'Data', width: 120, header: 'Reading (%)', type: 'number', elasticFilter: false, filterable: false },
		{
			field: 'Date',
			header: 'Date/time stamp',
			type: 'date',
			isLocal: false,
			isUtc: true,
			elasticFilter: false,
			showDateOnly: false,
			time: true,
			flex: 1
		},

		// { field: 'avg', flex: 1, header: 'Reading (%)', type: 'number', elasticFilter: false },
		// { field: 'min', flex: 1, header: 'Min (%)', type: 'number', elasticFilter: false },
		// { field: 'max', flex: 1, header: 'Max (%)', type: 'number', elasticFilter: false }
	]
};

export default config;
