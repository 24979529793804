export const HomeIcon = ({ color }) => <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.321" height="21.824" viewBox="0 0 25.321 21.824">
    <path id="Icon_metro-home" data-name="Icon metro-home" d="M26.191,17.767,14.381,8.6,2.571,17.767V14.029l11.81-9.167,11.81,9.168ZM23.239,15.9V24.76H17.334V18.855H11.428V24.76H5.523V15.9l8.858-6.643Z" transform="translate(-1.721 -3.785)" fill="none" stroke={color} strokeWidth="1.7" />
</svg>


export const LogoutIcon = ({ color }) => <svg xmlns="http://www.w3.org/2000/svg" width="17.545" height="16.096" viewBox="0 0 17.545 16.096">
    <g id="Group_23664" data-name="Group 23664" transform="translate(-40.987 -677.207)">
        <path id="Path_43025" data-name="Path 43025" d="M72.6,693.615H84.794v14.1H72.6" transform="translate(-27.262 -15.408)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2" />
        <g id="Icon_feather-arrow-right" data-name="Icon feather-arrow-right" transform="translate(41.837 681.753)">
            <path id="Path_43026" data-name="Path 43026" d="M7.5,18h7" transform="translate(-7.5 -14.499)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" />
            <path id="Path_43027" data-name="Path 43027" d="M18,7.5,21.5,11,18,14.5" transform="translate(-12.499 -7.5)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" />
        </g>
    </g>
</svg>


export const NotificationIcon = ({ color }) => <svg xmlns="http://www.w3.org/2000/svg" width="22.149" height="24.383" viewBox="0 0 22.149 24.383">
    <g id="Icon_feather-bell" data-name="Icon feather-bell" transform="translate(1 1)">
        <path id="Path_2997" data-name="Path 2997" d="M21.29,9.716a6.716,6.716,0,1,0-13.432,0C7.858,17.552,4.5,19.79,4.5,19.79H24.649S21.29,17.552,21.29,9.716" transform="translate(-4.5 -3)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <path id="Path_2998" data-name="Path 2998" d="M19.278,31.5a2.239,2.239,0,0,1-3.873,0" transform="translate(-7.267 -10.232)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </g>
</svg>

export const SettingsIcon = ({ color }) => <svg xmlns="http://www.w3.org/2000/svg" width="25.383" height="25.383" viewBox="0 0 25.383 25.383">
    <g id="Group_6247" data-name="Group 6247" transform="translate(1 1)">
        <g id="Icon_feather-settings" data-name="Icon feather-settings">
            <path id="Path_2881" data-name="Path 2881" d="M19.877,16.689A3.189,3.189,0,1,1,16.689,13.5,3.189,3.189,0,0,1,19.877,16.689Z" transform="translate(-4.997 -4.997)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            <path id="Path_2882" data-name="Path 2882" d="M21.057,16.38a1.754,1.754,0,0,0,.351,1.934l.064.064a2.127,2.127,0,1,1-3.008,3.008l-.064-.064a1.768,1.768,0,0,0-3,1.254v.181a2.126,2.126,0,0,1-4.252,0v-.1A1.754,1.754,0,0,0,10,21.057a1.754,1.754,0,0,0-1.934.351L8,21.471A2.127,2.127,0,1,1,5,18.464l.064-.064a1.768,1.768,0,0,0-1.254-3H3.626a2.126,2.126,0,1,1,0-4.252h.1A1.754,1.754,0,0,0,5.326,10a1.754,1.754,0,0,0-.351-1.934L4.912,8A2.127,2.127,0,1,1,7.92,5l.064.064a1.754,1.754,0,0,0,1.934.351H10a1.754,1.754,0,0,0,1.063-1.6V3.626a2.126,2.126,0,1,1,4.252,0v.1a1.768,1.768,0,0,0,3,1.254l.064-.064A2.127,2.127,0,1,1,21.386,7.92l-.064.064a1.754,1.754,0,0,0-.351,1.934V10a1.754,1.754,0,0,0,1.6,1.063h.181a2.126,2.126,0,0,1,0,4.252h-.1a1.754,1.754,0,0,0-1.6,1.063Z" transform="translate(-1.5 -1.5)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </g>
    </g>
</svg>

export const User_PicIcon = ({ color }) => <svg
    xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
    <defs>
        <clipPath id="clip-path">
            <circle id="Ellipse_9" data-name="Ellipse 9" cx="26.5" cy="26.5" r="26.5" fill="none" opacity="0.285" />
        </clipPath>
        <clipPath id="clip-path-2">
            <rect id="Rectangle_693" data-name="Rectangle 693" width="40.869" height="45.932" fill="none" />
        </clipPath>
    </defs>
    <g id="Mask_Group_5" data-name="Mask Group 5" clipPath="url(#clip-path)">
        <g id="Group_6250" data-name="Group 6250" transform="translate(5.065 7.068)">
            <g id="Group_6250-2" data-name="Group 6250" clipPath="url(#clip-path-2)">
                <path id="Path_2970" data-name="Path 2970" d="M17.742,0,31.685.015a4.1,4.1,0,0,1,3.283,3.516c.272,1.987.046,3.993.127,5.987.022.547-.327.636-.7.752A33.979,33.979,0,0,1,20.82,11.436a36.475,36.475,0,0,1-5.63-1.064c-.626-.165-1-.379-.961-1.158.079-1.682.006-3.37.058-5.054A4.315,4.315,0,0,1,17.742,0" transform="translate(-4.11 0)" fill={color} />
                <path id="Path_2971" data-name="Path 2971" d="M23.641,31.549c-2.694-.179-5.207-2.032-6.755-5.246a12.32,12.32,0,0,0-1.427-2.374,6.407,6.407,0,0,1-1.032-5.576c.34-1.538,1.579-1.952,2.866-1.083.446.3.443.661-.029,1.011-1.248.923-1.092,3.885.214,4.795.284.2.484.111.752.034a23.086,23.086,0,0,1,12.844-.078,1.151,1.151,0,0,0,1.484-.765,6.262,6.262,0,0,0,.356-3.229c-.026-.465-.435-.6-.8-.752a.526.526,0,0,1-.4-.515c0-.326.208-.474.477-.6,1.638-.74,2.635-.241,2.826,1.575.226,2.152.049,4.243-1.71,5.838a3.688,3.688,0,0,0-.714,1.454,9.163,9.163,0,0,1-8.954,5.5" transform="translate(-4.106 -4.86)" fill={color} />
                <path id="Path_2972" data-name="Path 2972" d="M37.948,41.916a38.532,38.532,0,0,0-8.261-3.528,1.176,1.176,0,0,0-1.6.565A121.505,121.505,0,0,1,20.4,48.937c-2.615-3.358-5.29-6.583-7.646-10.054-.282-.414-.449-.794-1.173-.585a38.3,38.3,0,0,0-8.808,3.732A5.165,5.165,0,0,0,0,46.148l0,7.089c.329,2.4,1.466,3.732,3.3,3.735q17.105.022,34.211,0a2.875,2.875,0,0,0,2.514-1.466,5.419,5.419,0,0,0,.77-3.024,52.944,52.944,0,0,1,.037-5.408c.257-2.482-.961-4.024-2.888-5.161m-2.914,7.549c-1-.122-1.539.129-1.352,1.28a.957.957,0,0,1-1.03,1.234c-.707-.023-1-.556-.914-1.232.131-.961-.208-1.358-1.205-1.231-.677.087-1.309-.1-1.311-.943,0-.893.639-1.1,1.374-1,.821.1,1.117-.186,1.018-1.013-.093-.776.139-1.489,1.05-1.5.932-.01,1.132.706,1.022,1.483-.129.915.346,1.043,1.085.973.721-.068,1.335.085,1.457.971-.114.663-.484,1.067-1.192.98" transform="translate(0 -11.048)" fill={color} />
            </g>
        </g>
        <circle id="Ellipse_230" data-name="Ellipse 230" cx="26.5" cy="26.5" r="26.5" fill="none" />
    </g>
</svg>
// new
export const DoorIcon = ({ color }) => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 400 400" height="30" width="30"> {/* Class style is not relecting here, that's inline style used */}
    <g >
        <path class="st0" fill={color} d="M87.42,35.35c4.22-0.27,7.23-0.63,10.25-0.63c21.47-0.05,42.94-0.26,64.41,0.08
		c9.43,0.15,18.41-1.23,27.35-4.08c14.51-4.62,29.11-8.93,43.71-13.25c2.16-0.64,4.52-0.6,7.69-0.98c0.31,6.34,0.58,11.89,0.9,18.4
		c23.08,0,45.86,0,69.66,0c0,101.23,0,201.49,0,302.72c15.66,0,30.3,0,45.79,0c-0.17,2.91-0.29,4.79-0.44,7.35
		c-38.84,0-76.87,0-115.46,0c0,12.59,0,24.46,0,35.73c-3.01,3.38-5.77,1.96-8.45,1.15c-36.72-11.01-73.47-21.9-110.11-33.14
		c-8.54-2.62-17-3.99-25.93-3.8c-15.5,0.34-31.01,0.09-46.52,0.08c-2.35,0-4.7,0-7.81,0c-0.2-2.47-0.35-4.35-0.59-7.28
		c15.53,0,30.17,0,45.57,0C87.42,236.67,87.42,136.6,87.42,35.35z M233.58,374.15c0-116.62,0-232.42,0-348.89
		C193.73,37.2,154.39,49,114.98,60.81c0,92.8,0,185.03,0,277.77C154.45,350.42,193.79,362.22,233.58,374.15z M283.86,337.25
		c0-92.03,0-183.6,0-275.04c-14.37,0-28.13,0-42.24,0c0,91.82,0,183.24,0,275.04C255.72,337.25,269.44,337.25,283.86,337.25z
		 M291.66,54.68c0.19,4.6,0.49,8.56,0.49,12.51c0.03,87.26,0.02,174.52,0.02,261.77c0,2.74,0,5.47,0,8.13c4.63,0,8.06,0,11.53,0
		c0-98.41,0-196.29,0-294.04c-20.98,0-41.53,0-61.91,0c0,4.08,0,7.5,0,11.62C258.46,54.68,274.59,54.68,291.66,54.68z
		 M106.95,337.36c0-23.83,0-47.15,0-70.47c0-23.61,0-47.23,0-70.84c0-23.38-0.04-46.75,0.01-70.13c0.05-23.49-0.24-46.99,0.19-70.5
		c13.3-4.09,25.48-7.84,37.66-11.58c-0.11-0.31-0.22-0.62-0.34-0.94c-16.32,0-32.65,0-48.98,0c0,98.39,0,196.29,0,294.45
		C99.23,337.36,102.49,337.36,106.95,337.36z"></path>
        <path class="st0" fill={color} d="M193.44,204.99c-7.12,0.81-14.36,1.63-22.43,2.54c-0.26-2.61-0.45-4.46-0.7-6.95c8-1.17,15.22-2.23,22.37-3.28
		c5.37-9.14,12.34-11.79,18.96-7.29c5.65,3.84,6.8,11.49,2.56,16.92C209.07,213.51,202.04,212.89,193.44,204.99z"></path>
    </g>
</svg>

// new
export const HumidityIcon = ({ color }) => { }

// new
export const TemperatureIcon = ({ color }) => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 400 400" width="30" height="30" style={{ marginLeft: "-0.3em" }}> {/* Class style is not relecting here, that's inline style used */}
    <g>
        <path class="st0" fill="none" d="M246.99,119.81c0.03,0,0.05,0,0.08,0V97.44c-0.03,0-0.05,0-0.08,0C246.99,104.97,246.99,112.3,246.99,119.81z"></path>
        <path class="st0" fill="none" d="M247.03,153.29c0.02,0,0.03,0,0.05,0v-22.5c-0.02,0-0.03,0-0.05,0C247.03,138.34,247.03,145.73,247.03,153.29z"></path>
        <path class="st0" fill="none" d="M247.05,186.68c0.01,0,0.02,0,0.02,0v-22.42c-0.01,0-0.02,0-0.02,0C247.05,171.74,247.05,179.12,247.05,186.68z"></path>
        <path class="st0" fill="none" d="M232.03,248.52c-1.27-0.61-1.65-1.31-1.65-2.7c0.04-32.5,0.03-65,0.03-97.49c0-32.31-0.01-64.62,0.02-96.93
	c0-3.35-0.94-6.31-2.98-8.93c-4.31-5.54-9.98-9.18-16.82-10.25c-5.11-0.8-10.4-0.43-15.61-0.61c-5.65-0.19-10.8,1.44-15.53,4.41
	c-6.64,4.17-10.31,9.65-10.28,18.12c0.23,63.74,0.12,127.47,0.16,191.21c0,1.72-0.45,2.66-2.05,3.42
	c-9.77,4.65-17.4,11.67-22.45,21.29c-6.87,13.08-9.05,27.15-7.71,41.7c2.74,29.89,26.02,53.46,55.68,56.77
	c29.15,3.25,56.69-14,66.47-41.58c4.19-11.83,4.47-23.92,2.39-36.12C258.53,272.02,249.82,257.04,232.03,248.52z"></path>
        <path class="st1" fill={color} d="M247.05,186.68c0-7.55,0-14.94,0-22.42c0.01,0,0.02,0,0.02,0v-10.96c-0.02,0-0.03,0-0.05,0
	c0-7.56,0-14.95,0-22.5c0.02,0,0.03,0,0.05,0v-10.98c-0.03,0-0.05,0-0.08,0c0-7.51,0-14.85,0-22.37c0.03,0,0.05,0,0.08,0V86.43
	c-0.05,0-0.1,0-0.15,0c0-0.84,0-1.51,0-2.18c0-11.02-0.02-22.04,0.01-33.06c0.01-6.36-1.73-12.18-5.39-17.36
	c-6.9-9.76-16.37-15.82-28.02-17.84c-6.07-1.06-12.39-0.71-18.6-0.87c-9.42-0.24-17.97,2.63-25.66,7.91
	c-10.8,7.42-16.82,17.29-16.47,30.94c0.41,16.11,0.1,32.24,0.1,48.36c0,44.52,0,89.05,0.01,133.57c0,1.15,0,2.08-1.21,2.87
	c-9.15,5.95-16.2,13.85-21.37,23.44c-6.3,11.68-9.07,24.27-9.83,37.42c-1.19,20.63,4.18,39.24,17.03,55.47
	c12.3,15.53,28.27,25.19,47.75,28.93c2.48,0.48,4.96,0.94,7.44,1.41c4.4,0,8.81,0,13.21,0c2.31-0.37,4.63-0.68,6.93-1.1
	c27.2-5.04,46.85-20.11,59.08-44.88c4.18-8.47,6.42-17.54,7.23-26.96c0.02-0.26,0.24-0.5,0.36-0.75c0-4.41,0-8.82,0-13.23
	c-0.12-0.31-0.31-0.62-0.36-0.95c-0.9-5.71-1.38-11.52-2.75-17.11c-4.26-17.43-13.28-31.76-28.38-41.91
	c-0.6-0.41-1.04-1.51-1.05-2.29c-0.06-12.28-0.05-24.56-0.04-36.84c0-0.55,0.05-1.09,0.08-1.78c0.02,0,0.04,0,0.06,0v-10.99
	C247.06,186.68,247.06,186.68,247.05,186.68z M261.72,290.81c2.07,12.21,1.8,24.29-2.39,36.12c-9.78,27.58-37.32,44.84-66.47,41.58
	c-29.67-3.31-52.94-26.88-55.68-56.77c-1.33-14.55,0.85-28.62,7.71-41.7c5.05-9.62,12.68-16.64,22.45-21.29
	c1.6-0.76,2.05-1.7,2.05-3.42c-0.05-63.74,0.06-127.47-0.16-191.21c-0.03-8.47,3.65-13.94,10.28-18.12
	c4.72-2.97,9.88-4.6,15.53-4.41c5.21,0.18,10.5-0.18,15.61,0.61c6.84,1.07,12.51,4.71,16.82,10.25c2.04,2.62,2.98,5.58,2.98,8.93
	c-0.03,32.31-0.02,64.62-0.02,96.93c0,32.5,0.01,65-0.03,97.49c0,1.39,0.38,2.09,1.65,2.7
	C249.82,257.04,258.53,272.02,261.72,290.81z"></path>
        <path class="st1" fill={color} d="M248.88,97.44c6.41,0,12.83,0.03,19.24-0.01c3.35-0.02,5.72-2.34,5.75-5.47c0.03-3.15-2.26-5.46-5.66-5.51
	c-4.46-0.07-8.93-0.02-13.4-0.02c-2.52,0-5.04,0-7.76,0v11.01C247.71,97.44,248.3,97.44,248.88,97.44z"></path>
        <path class="st1" fill={color} d="M248.97,130.79c6.35,0,12.7,0.02,19.06-0.01c3.49-0.01,5.88-2.29,5.86-5.5c-0.02-3.2-2.42-5.44-5.93-5.46
	c-4.72-0.03-9.43-0.01-14.15-0.01c-2.22,0-4.45,0-6.73,0v10.98C247.76,130.79,248.37,130.79,248.97,130.79z"></path>
        <path class="st1" fill={color} d="M267.15,164.26c4.23,0,6.7-2,6.73-5.44c0.04-3.52-2.42-5.52-6.79-5.52c-4.47,0-8.93,0-13.4,0
	c-2.17,0-4.35,0-6.62,0v10.96C253.85,164.26,260.5,164.26,267.15,164.26z"></path>
        <path class="st1" fill={color} d="M267.84,197.66c3.62-0.01,6.06-2.26,6.05-5.53c-0.01-3.25-2.45-5.44-6.12-5.45c-4.34-0.02-8.68,0-13.02,0
	c-2.56,0-5.11,0-7.67,0v10.99c0.7,0,1.3,0,1.9,0C255.26,197.67,261.55,197.68,267.84,197.66z"></path>
        <path class="st2" fill={color} d="M211.73,262.11c0.04-15.73,0.03-31.47,0.03-47.2c0-15.39,0.01-30.79-0.03-46.18c0-1.22-0.14-2.47-0.63-3.64
	c-1.43-3.42-6-5.74-11.06-5.78c-5.06-0.04-9.55,2.09-11.17,5.5c-0.58,1.22-0.76,2.56-0.76,3.85c-0.04,31.1-0.04,62.2,0.01,93.29
	c3.62-0.6,7.43-0.93,11.36-0.93C203.74,261.02,207.84,261.4,211.73,262.11z"></path>
        <circle class="st2" fill={color} cx="199.49" cy="303.33" r="44.22"></circle>
    </g>
</svg>

export const Alert_definitionsIcon = ({ color }) => <svg xmlns="http://www.w3.org/2000/svg" width="19.453" height="17.037" viewBox="0 0 19.453 17.037">
    <g id="Icon_feather-alert-triangle" data-name="Icon feather-alert-triangle" transform="translate(-1.454 -3.496)">
        <path id="Path_43001" data-name="Path 43001" d="M9.731,5.162,2.555,17.141A1.694,1.694,0,0,0,4,19.683H18.355A1.694,1.694,0,0,0,19.8,17.141L12.628,5.162a1.694,1.694,0,0,0-2.9,0Z" transform="translate(0 0)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" />
        <path id="Path_43002" data-name="Path 43002" d="M18,13.5v3.389" transform="translate(-6.82 -3.984)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" />
        <path id="Path_43003" data-name="Path 43003" d="M18,25.5h0" transform="translate(-6.82 -9.206)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" />
    </g>
</svg>


export const Alert_recipientsIcon = ({ color }) => <svg xmlns="http://www.w3.org/2000/svg" width="20.482" height="16.078" viewBox="0 0 20.482 16.078">
    <g id="Group_23555" data-name="Group 23555" transform="translate(-67.479 -658.443)">
        <g id="Group_23555-2" data-name="Group 23555" transform="translate(-4.27 212.746)">
            <g id="Group_23546" data-name="Group 23546" transform="translate(6 -316)">
                <g id="_6fwRkP.tif" data-name="6fwRkP.tif" transform="translate(-230.998 616.949)" opacity="0.999">
                    <g id="Group_1189" data-name="Group 1189" transform="translate(297 145)">
                        <g id="Group_1188" data-name="Group 1188">
                            <path id="Subtraction_1" data-name="Subtraction 1" d="M22618.375-8455.373h-8.484a.721.721,0,0,1-.393-.392v-3.566l.006-.021a1.335,1.335,0,0,0,.033-.2,4.728,4.728,0,0,1,1.414-2.791,4.768,4.768,0,0,1,2.846-1.31c.158-.02.32-.025.477-.032l.273-.013-.072-.054-.064-.049a4.014,4.014,0,0,1-1.141-4.021,3.994,3.994,0,0,1,2.9-2.971,4.671,4.671,0,0,1,.5-.115l.223-.044h.777c.049.013.1.021.146.03l.033.006.025,0a4.006,4.006,0,0,1,3.346,2.84,3.987,3.987,0,0,1-1.074,4.282c-.018.018-.035.038-.055.059l-.014.016-.018.022.148.006c.215.009.393.017.58.033a4.649,4.649,0,0,1,1.127.252l-.6.895a3.679,3.679,0,0,0-.975-.136c-.914,0-1.916,0-3.064,0s-2.15,0-3.064,0a3.711,3.711,0,0,0-3.549,2.755,4.207,4.207,0,0,0-.131.971c-.012.557-.012,1.112-.006,1.774v.72h7.479l.357,1.049Zm-1.084-14.538a3.12,3.12,0,0,0-3.119,3.1,3.118,3.118,0,0,0,3.1,3.129h.012a3.12,3.12,0,0,0,3.117-3.1,3.111,3.111,0,0,0-.9-2.208,3.09,3.09,0,0,0-2.2-.921Z" transform="translate(-22609.5 8470.949)" fill={color} stroke={color} stroke-miterlimit="10" strokeWidth="0.5" />
                            <path id="Path_1979" data-name="Path 1979" d="M297,613a.693.693,0,0,0,.389.389H297Z" transform="translate(-297 -597.815)" fill={color} stroke={color} strokeWidth="0.5" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
        <g id="Icon_feather-alert-triangle" data-name="Icon feather-alert-triangle" transform="translate(76.523 662.137)">
            <path id="Path_43001" data-name="Path 43001" d="M5.881,4.737,2.437,10.487a.813.813,0,0,0,.7,1.22H10.02a.813.813,0,0,0,.7-1.22L7.271,4.737a.813.813,0,0,0-1.391,0Z" transform="translate(0 0)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
            <path id="Path_43002" data-name="Path 43002" d="M18,13.5v1.626" transform="translate(-11.424 -6.673)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
            <path id="Path_43003" data-name="Path 43003" d="M18,25.5h0" transform="translate(-11.424 -15.42)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
        </g>
    </g>
</svg>


export const CasesIcon = ({ color }) => <svg xmlns="http://www.w3.org/2000/svg" width="18.993" height="20.775" viewBox="0 0 18.993 20.775">
    <g id="Icon_feather-file-text" data-name="Icon feather-file-text" transform="translate(-5.15 -2.15)">
        <path id="Path_42994" data-name="Path 42994" d="M18.748,3H8.162A2.047,2.047,0,0,0,6,4.907v15.26a2.047,2.047,0,0,0,2.162,1.907h12.97a2.047,2.047,0,0,0,2.162-1.907L23.238,7.69Z" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" />
        <path id="Path_42995" data-name="Path 42995" d="M21,3V7.573h4.387" transform="translate(-2.28)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" />
        <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(6.073 3.965)">
            <path id="Path_42999" data-name="Path 42999" d="M11.74,8.12A3.62,3.62,0,1,1,8.12,4.5,3.62,3.62,0,0,1,11.74,8.12Z" transform="translate(0 0)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" />
            <path id="Path_43000" data-name="Path 43000" d="M26.943,26.943l-1.968-1.968" transform="translate(-14.298 -14.298)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" />
        </g>
    </g>
</svg>

export const DashboardIcon = ({ color }) => <svg xmlns="http://www.w3.org/2000/svg" width="19.574" height="19.574" viewBox="0 0 19.574 19.574">
    <g id="rgMtye.tif" transform="translate(29 38)">
        <g id="Group_1191" data-name="Group 1191" transform="translate(-29 -38)">
            <g id="Group_1190" data-name="Group 1190">
                <path id="Path_1982" data-name="Path 1982" d="M289.334,129c.54.194.652.6.648,1.135-.018,2.305-.008,4.611-.007,6.916a.91.91,0,0,1-.217.653.82.82,0,0,1-.63.27q-3.64,0-7.279,0a.865.865,0,0,1-.849-.64V129.65a.952.952,0,0,1,.65-.65Zm-1,1.648h-5.686v5.681h5.686Z" transform="translate(-281 -129)" fill={color} />
                <path id="Path_1983" data-name="Path 1983" d="M567.073,137.334a.955.955,0,0,1-1.04.646c-2.324-.013-4.649-.006-6.973-.006a.845.845,0,0,1-.961-.961c0-2.324.007-4.649-.006-6.973a.955.955,0,0,1,.646-1.04h7.684a.952.952,0,0,1,.65.65Zm-1.643-6.684h-5.683v5.677h5.683Z" transform="translate(-547.499 -129)" fill={color} />
                <path id="Path_1984" data-name="Path 1984" d="M281,406.738a.955.955,0,0,1,1.04-.646c2.325.013,4.649.006,6.974.006a.875.875,0,0,1,.968,1.018c-.018.617-.006,1.235-.007,1.853,0,1.688-.009,3.376.006,5.063a.953.953,0,0,1-.646,1.04H281.65a.952.952,0,0,1-.65-.65Zm7.327,6.692v-5.685H282.65v5.685Z" transform="translate(-281 -395.499)" fill={color} />
                <path id="Path_1985" data-name="Path 1985" d="M558.739,415.073a.955.955,0,0,1-.646-1.04c.013-2.324.005-4.649.006-6.973a.845.845,0,0,1,.961-.961c2.324,0,4.649.007,6.973-.006a.955.955,0,0,1,1.04.646v7.684a.953.953,0,0,1-.65.65Zm1-1.648h5.686v-5.681H559.74Z" transform="translate(-547.499 -395.499)" fill={color} />
                <path id="Path_1986" data-name="Path 1986" d="M281.65,129a.952.952,0,0,0-.65.65V129Z" transform="translate(-281 -129)" fill={color} />
                <path id="Path_1987" data-name="Path 1987" d="M776.65,129.65A.952.952,0,0,0,776,129h.65Z" transform="translate(-757.076 -129)" fill={color} />
                <path id="Path_1988" data-name="Path 1988" d="M281,624a.952.952,0,0,0,.65.65H281Z" transform="translate(-281 -605.076)" fill={color} />
                <path id="Path_1989" data-name="Path 1989" d="M776,624.65a.953.953,0,0,0,.65-.65v.65Z" transform="translate(-757.076 -605.076)" fill={color} />
            </g>
        </g>
    </g>
</svg>

export const Hamburger_collapsedIcon = ({ color }) => <svg xmlns="http://www.w3.org/2000/svg" width="26.182" height="18.431" viewBox="0 0 26.182 18.431">
    <g id="Symbol_2" data-name="Symbol 2" transform="translate(-115 -196.608)">
        <g id="Group_22" data-name="Group 22" transform="translate(115 204.75)">
            <g id="Group_21" data-name="Group 21" transform="translate(0 0)">
                <path id="Path_14" data-name="Path 14" d="M22.21,235.318H-2.028A1.026,1.026,0,0,0-3,236.391a1.026,1.026,0,0,0,.972,1.073H22.21a1.079,1.079,0,0,0,0-2.147Z" transform="translate(3 -235.318)" fill={color} />
            </g>
        </g>
        <g id="Group_24" data-name="Group 24" transform="translate(120.145 196.608)">
            <g id="Group_23" data-name="Group 23" transform="translate(0 0)">
                <path id="Path_15" data-name="Path 15" d="M24.2,78.439H6.38a1.107,1.107,0,1,0,0,2.147H24.2a1.107,1.107,0,1,0,0-2.147Z" transform="translate(-5 -78.439)" fill={color} />
            </g>
        </g>
        <g id="Group_26" data-name="Group 26" transform="translate(117.498 212.892)">
            <g id="Group_25" data-name="Group 25" transform="translate(0 0)">
                <path id="Path_16" data-name="Path 16" d="M24.039,392.2H3.34a1.1,1.1,0,1,0,0,2.147h20.7a1.1,1.1,0,1,0,0-2.147Z" transform="translate(-2 -392.197)" fill={color} />
            </g>
        </g>
    </g>
</svg>

export const Hamburger_openedIcon = ({ color }) => <svg xmlns="http://www.w3.org/2000/svg" width="26.182" height="18.431" viewBox="0 0 26.182 18.431">
    <g id="Symbol_2" data-name="Symbol 2" transform="translate(141.182 215.039) rotate(180)">
        <g id="Group_22" data-name="Group 22" transform="translate(115 204.75)">
            <g id="Group_21" data-name="Group 21" transform="translate(0 0)">
                <path id="Path_14" data-name="Path 14" d="M22.21,235.318H-2.028A1.026,1.026,0,0,0-3,236.391a1.026,1.026,0,0,0,.972,1.073H22.21a1.079,1.079,0,0,0,0-2.147Z" transform="translate(3 -235.318)" fill={color} />
            </g>
        </g>
        <g id="Group_24" data-name="Group 24" transform="translate(120.145 196.608)">
            <g id="Group_23" data-name="Group 23" transform="translate(0 0)">
                <path id="Path_15" data-name="Path 15" d="M24.2,78.439H6.38a1.107,1.107,0,1,0,0,2.147H24.2a1.107,1.107,0,1,0,0-2.147Z" transform="translate(-5 -78.439)" fill={color} />
            </g>
        </g>
        <g id="Group_26" data-name="Group 26" transform="translate(117.498 212.892)">
            <g id="Group_25" data-name="Group 25" transform="translate(0 0)">
                <path id="Path_16" data-name="Path 16" d="M24.039,392.2H3.34a1.1,1.1,0,1,0,0,2.147h20.7a1.1,1.1,0,1,0,0-2.147Z" transform="translate(-2 -392.197)" fill={color} />
            </g>
        </g>
    </g>
</svg>

export const Mqtt_clientsIcon = ({ color }) => <svg xmlns="http://www.w3.org/2000/svg" width="23.692" height="16.072" viewBox="0 0 23.692 16.072">
    <g id="Group_23552" data-name="Group 23552" transform="translate(-71.75 -445.701)">
        <g id="Group_23546" data-name="Group 23546" transform="translate(6 -316)">
            <g id="_6fwRkP.tif" data-name="6fwRkP.tif" transform="translate(-230.998 616.949)" opacity="0.999">
                <g id="Group_1189" data-name="Group 1189" transform="translate(297 145)">
                    <g id="Group_1188" data-name="Group 1188">
                        <path id="Subtraction_1" data-name="Subtraction 1" d="M22618.967-8455.375h-9.076a.68.68,0,0,1-.393-.392v-3.566a2.224,2.224,0,0,0,.039-.222,4.761,4.761,0,0,1,1.414-2.79,4.764,4.764,0,0,1,2.846-1.311c.129-.013.258-.018.393-.023h.031c.115,0,.223-.01.326-.016l-.047-.034-.018-.014a.871.871,0,0,1-.072-.054,4.032,4.032,0,0,1-1.141-4.027,4.006,4.006,0,0,1,2.9-2.97,3.971,3.971,0,0,1,.49-.108l.068-.013.168-.032h.777l.025,0,.033.006c.047.009.1.018.146.03a3.989,3.989,0,0,1,3.346,2.839,3.98,3.98,0,0,1-1.074,4.282l-.021.021c-.025.025-.045.047-.064.069.105.008.209.012.307.016h.012c.152.006.277.012.41.024a4.967,4.967,0,0,1,.66.113l-.9.9c-.059,0-.121-.006-.2-.007l-2.043,0-2.041,0h-2.045a3.714,3.714,0,0,0-3.549,2.755,4.173,4.173,0,0,0-.131.977c-.012.553-.012,1.107-.006,1.771v.716h7.9l.529,1.05Zm-1.666-14.539a3.126,3.126,0,0,0-3.129,3.1,3.121,3.121,0,0,0,3.1,3.129h.012a3.117,3.117,0,0,0,3.117-3.095A3.122,3.122,0,0,0,22617.3-8469.914Z" transform="translate(-22609.5 8470.949)" fill={color} stroke={color} stroke-miterlimit="10" strokeWidth="0.5" />
                        <path id="Path_1979" data-name="Path 1979" d="M297,613a.693.693,0,0,0,.389.389H297Z" transform="translate(-297 -597.815)" fill={color} stroke={color} strokeWidth="0.5" />
                    </g>
                </g>
            </g>
        </g>
        <g id="Group_23551" data-name="Group 23551" transform="translate(17.953 -311.664)">
            <g id="_6fwRkP.tif-2" data-name="6fwRkP.tif" transform="translate(66 761.951)" opacity="0.999">
                <g id="Group_1189-2" data-name="Group 1189" transform="translate(0 0)">
                    <g id="Group_1188-2" data-name="Group 1188" transform="translate(0 0)">
                        <path id="Subtraction_1-2" data-name="Subtraction 1" d="M0,10.955V8.38c.009-.053.021-.105.028-.158A3.432,3.432,0,0,1,3.1,5.262c.168-.017.337-.019.542-.03a1.067,1.067,0,0,1-.1-.074,2.9,2.9,0,0,1-.823-2.9A2.889,2.889,0,0,1,4.817.111c.17-.051.348-.075.522-.112H5.9l.146.028A2.9,2.9,0,0,1,8.464,2.076a2.89,2.89,0,0,1-.778,3.091c-.02.02-.038.042-.06.068.182.01.354.013.524.029A3.421,3.421,0,0,1,11.123,7.8c.05.191.078.387.116.581v2.575a.5.5,0,0,1-.281.281H.282A.5.5,0,0,1,0,10.955Zm.749-.476H10.49v-.1c0-.577,0-1.155,0-1.732A2.682,2.682,0,0,0,7.832,5.993q-2.212-.007-4.423,0A2.668,2.668,0,0,0,.847,7.978a3.121,3.121,0,0,0-.092.7C.742,9.278.751,9.875.751,10.479ZM5.631.748A2.247,2.247,0,1,0,7.868,3.007,2.248,2.248,0,0,0,5.631.748Z" fill={color} stroke={color} stroke-miterlimit="10" strokeWidth="0.5" />
                        <path id="Path_1979-2" data-name="Path 1979" d="M297,613a.5.5,0,0,0,.281.281H297Z" transform="translate(-296.999 -602.045)" fill={color} stroke={color} strokeWidth="0.5" />
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>


export const ReportsIcon = ({ color }) => <svg xmlns="http://www.w3.org/2000/svg" width="18.993" height="20.775" viewBox="0 0 18.993 20.775">
    <g id="Icon_feather-file-text" data-name="Icon feather-file-text" transform="translate(-5.15 -2.15)">
        <path id="Path_42994" data-name="Path 42994" d="M16.808,3H8.162A2.047,2.047,0,0,0,6,4.907v15.26a2.047,2.047,0,0,0,2.162,1.907h12.97a2.047,2.047,0,0,0,2.162-1.907V8.722Z" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" />
        <path id="Path_42995" data-name="Path 42995" d="M21,3V9.485h6.485" transform="translate(-4.192)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" />
        <path id="Path_42996" data-name="Path 42996" d="M20.646,19.5H12" transform="translate(-1.677 -6.009)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" />
        <path id="Path_42997" data-name="Path 42997" d="M20.646,25.5H12" transform="translate(-1.677 -8.194)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" />
        <path id="Path_42998" data-name="Path 42998" d="M14.162,13.5H12" transform="translate(-1.677 -3.824)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" />
    </g>
</svg>

export const RoomsIcon = ({ color }) => <svg xmlns="http://www.w3.org/2000/svg" width="19.574" height="17.071" viewBox="0 0 19.574 17.071">
    <g id="Group_23549" data-name="Group 23549" transform="translate(-70 -352.965)">
        <g id="Rectangle_7001" data-name="Rectangle 7001" transform="translate(70 352.965)" fill="none" stroke={color} strokeWidth="1.7">
            <rect width="19.574" height="17.071" stroke="none" />
            <rect x="0.85" y="0.85" width="17.874" height="15.371" fill="none" />
        </g>
        <g id="Rectangle_7002" data-name="Rectangle 7002" transform="translate(77 360)" fill="none" stroke={color} strokeWidth="1.7">
            <rect width="6" height="10" stroke="none" />
            <rect x="0.85" y="0.85" width="4.3" height="8.3" fill="none" />
        </g>
    </g>
</svg>


export const SensorIcon = ({ color }) => <svg xmlns="http://www.w3.org/2000/svg" width="20.498" height="16.291" viewBox="0 0 20.498 16.291">
    <g id="Group_23550" data-name="Group 23550" transform="translate(-69.076 -314.721)">
        <path id="Icon_metro-wifi-mid" data-name="Icon metro-wifi-mid" d="M3.188,6.563l1.4,1.4a5.025,5.025,0,0,1,7.116,0l1.4-1.4a7.01,7.01,0,0,0-9.914,0ZM8.145,0A11.483,11.483,0,0,0,0,3.374l1.4,1.4a9.524,9.524,0,0,1,13.488,0l1.4-1.4A11.483,11.483,0,0,0,8.145,0Zm0,8.621A1.482,1.482,0,1,0,9.628,10.1,1.483,1.483,0,0,0,8.145,8.621Z" transform="translate(69.076 331.012) rotate(-90)" fill={color} />
        <path id="Icon_metro-wifi-mid-2" data-name="Icon metro-wifi-mid" d="M3.188,6.563l1.4,1.4a5.025,5.025,0,0,1,7.116,0l1.4-1.4a7.01,7.01,0,0,0-9.914,0ZM8.145,0A11.483,11.483,0,0,0,0,3.374l1.4,1.4a9.524,9.524,0,0,1,13.488,0l1.4-1.4A11.483,11.483,0,0,0,8.145,0Zm0,8.621A1.482,1.482,0,1,0,9.628,10.1,1.483,1.483,0,0,0,8.145,8.621Z" transform="translate(89.574 314.721) rotate(90)" fill={color} />
    </g>
</svg>

export const SetupIcon = ({ color }) => <svg xmlns="http://www.w3.org/2000/svg" width="19.874" height="20.234" viewBox="0 0 19.874 20.234">
    <g id="Group_1381" data-name="Group 1381" transform="translate(-275.85 -344.832)">
        <path id="Path_2181" data-name="Path 2181" d="M294.383,355.8a1.175,1.175,0,0,1-.033-2.117,1.918,1.918,0,0,0,.8-2.8c-.451-.846-.913-1.687-1.437-2.489a1.81,1.81,0,0,0-2.558-.658,1.5,1.5,0,0,1-2.306-1.205,1.679,1.679,0,0,0-1.747-1.549q-1.581-.011-3.163,0a1.547,1.547,0,0,0-1.656,1.517,1.919,1.919,0,0,1-2.365,1.576,1.709,1.709,0,0,0-2.063.576A13.237,13.237,0,0,0,276,352.036v.72a1.943,1.943,0,0,0,.58,1.105,2.089,2.089,0,0,1,.141,3.009,1.655,1.655,0,0,0-.322,2.154c.446.9.978,1.757,1.505,2.611a1.786,1.786,0,0,0,2.477.728,1.825,1.825,0,0,1,2.374,1.189c.223.948.6,1.3,1.479,1.364H287.9c.987-.064,1.326-.4,1.382-1.359.008-.143,0-.288.01-.431a1.494,1.494,0,0,1,2.093-1.255,1.78,1.78,0,0,0,2.408-.6,11.906,11.906,0,0,0,1.777-3.337v-.576A1.941,1.941,0,0,0,294.383,355.8Zm-.766,3c-.275.476-.564.945-.815,1.434-.2.389-.438.464-.8.215-.156-.108-.346-.17-.5-.282a1.1,1.1,0,0,0-1.643.165,2.111,2.111,0,0,1-.76.532,1.669,1.669,0,0,0-1.134,2.042c.1.609-.116.873-.779.826a23.225,23.225,0,0,0-2.373-.006c-.459.015-.632-.137-.636-.616-.009-1.173-.037-1.158-1.118-1.588a5.777,5.777,0,0,1-1.331-.684,1.213,1.213,0,0,0-1.634,0c-.52.4-.855.35-1.157-.276a23.214,23.214,0,0,0-1.18-2.049c-.217-.352-.242-.63.186-.795.863-.333.941-.981.735-1.754a2.706,2.706,0,0,1-.07-1.354,1.226,1.226,0,0,0-.84-1.586c-.51-.23-.589-.523-.28-1.005.451-.7.865-1.432,1.256-2.17.221-.417.47-.515.841-.222a1.278,1.278,0,0,0,1.9-.106,3.651,3.651,0,0,1,1.169-.679,1.343,1.343,0,0,0,.953-1.484c-.053-1.054-.011-1.056,1.046-1.056.7,0,1.391.011,2.086,0,.449-.01.7.156.627.631a1.54,1.54,0,0,0,1.26,1.971.3.3,0,0,1,.12.077,2.1,2.1,0,0,0,3,.13.407.407,0,0,1,.668.111c.508.81,1.021,1.617,1.529,2.427a.913.913,0,0,1,.071.183.463.463,0,0,1-.28.476c-.287.171-.574.346-.872.5a.926.926,0,0,0-.536,1.183,3.622,3.622,0,0,1,.062,1.359,1.18,1.18,0,0,0,.669,1.291C294.417,357.413,294.407,357.429,293.617,358.8Z" transform="translate(0 0)" fill={color} stroke={color} strokeWidth="0.3" />
        <path id="Path_2182" data-name="Path 2182" d="M318.551,385.188a4.289,4.289,0,1,0,4.218,4.323A4.359,4.359,0,0,0,318.551,385.188Zm-.072,7.318a3.03,3.03,0,1,1,3.031-3A3.02,3.02,0,0,1,318.479,392.507Z" transform="translate(-32.695 -34.42)" fill={color} stroke={color} strokeWidth="0.3" />
    </g>
</svg>

export const TelemetriesIcon = ({ color }) => <svg xmlns="http://www.w3.org/2000/svg" width="23.745" height="23.445" viewBox="0 0 23.745 23.445">
    <g id="Group_23554" data-name="Group 23554" transform="translate(-70.001 -494.51)">
        <g id="Group_23553" data-name="Group 23553" transform="translate(70.001 494.51)">
            <path id="Icon_metro-wifi-mid" data-name="Icon metro-wifi-mid" d="M2.452,5.048,3.529,6.124A3.865,3.865,0,0,1,9,6.124l1.076-1.076a5.391,5.391,0,0,0-7.626,0ZM6.265,0A8.832,8.832,0,0,0,0,2.6L1.078,3.673a7.326,7.326,0,0,1,10.374,0L12.53,2.6A8.832,8.832,0,0,0,6.265,0Zm0,6.631a1.14,1.14,0,1,0,1.14,1.14A1.141,1.141,0,0,0,6.265,6.631Z" transform="matrix(0.574, 0.819, -0.819, 0.574, 16.558, 0)" fill={color} />
            <g id="Subtraction_22" data-name="Subtraction 22" transform="translate(0 9.411)" fill="none">
                <path d="M15.057,9.284H0V0H11.6a2.693,2.693,0,0,0,1.854,4.644,2.676,2.676,0,0,0,1.6-.525V9.283Z" stroke="none" />
                <path d="M 13.35663986206055 7.583695888519287 L 13.35663986206055 6.342401504516602 C 10.98232078552246 6.288367748260498 9.067580223083496 4.338734149932861 9.067580223083496 1.949955701828003 C 9.067580223083496 1.86619234085083 9.069886207580566 1.782892942428589 9.074475288391113 1.699995756149292 L 1.700000286102295 1.699995756149292 L 1.700000286102295 7.583695888519287 L 13.35663986206055 7.583695888519287 M 15.056640625 9.283696174621582 L 3.12499992105586e-07 9.283696174621582 L 3.12499992105586e-07 -4.296874976716936e-06 L 11.60449028015137 -4.296874976716936e-06 C 11.06543064117432 0.5133057236671448 10.76758003234863 1.205325722694397 10.76758003234863 1.949955701828003 C 10.76758003234863 3.435185670852661 11.97362995147705 4.643555641174316 13.45801067352295 4.643555641174316 C 14.03809070587158 4.643555641174316 14.5908203125 4.462035655975342 15.056640625 4.118655681610107 L 15.056640625 9.283696174621582 Z" stroke="none" fill={color} />
            </g>
            <line id="Line_354" data-name="Line 354" x2="7.691" transform="translate(3.46 22.445)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2" />
            <line id="Line_355" data-name="Line 355" y1="3.051" transform="translate(7.306 19.395)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2" />
        </g>
    </g>
</svg>

export const humidityIcon = ({ color }) => <svg xmlns="http://www.w3.org/2000/svg" width="31.745" height="31.445" viewBox="0 0 74 74" aria-labelledby="title"
    aria-describedby="desc" role="img">
    <desc>A line styled icon from Orion Icon Library.</desc>
    <path data-name="layer2"
        d="M51.9 40.1a20.6 20.6 0 0 0-1-4.9C46.9 20.8 32 2 32 2S17.1 20.8 13 35.2a20.6 20.6 0 0 0-1 4.9c0 .5-.1 1-.1 1.5A20.2 20.2 0 0 0 32 62a20.2 20.2 0 0 0 20-20.4c0-.5 0-1-.1-1.5z"
        fill="none" stroke={color} stroke-miterlimit="10" strokeWidth="4" strokeLinejoin="round"
        strokeLinecap="round"></path>
    <path data-name="layer1" fill="none" stroke={color} stroke-miterlimit="10"
        strokeWidth="4" d="M38 30L26 50" strokeLinejoin="round" strokeLinecap="round"></path>
    <circle data-name="layer1" cx="26" cy="32" r="4" fill="none" stroke={color}
        stroke-miterlimit="10" strokeWidth="4" strokeLinejoin="round" strokeLinecap="round"></circle>
    <circle data-name="layer1" cx="38" cy="48" r="4" fill="none"
        stroke={color} stroke-miterlimit="10" strokeWidth="4" strokeLinejoin="round"
        strokeLinecap="round"></circle>
</svg>
