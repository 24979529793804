import { roleModules } from 'dips-models';

const util = {
	dateFormat: 'YYYY-MM-DD hh:mm:ss A',
	setCookieWithoutExpiry: (cname, cvalue) => {
		document.cookie = cname + '=' + cvalue + ';';
	},
	getCookie: (cname) => {
		var name = cname + '=';
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return '';
	},
	hasPermission: ({ role, module }) => {
		const roleModel = roleModules[role.replace(/ /g, '').toLowerCase()];
		module = roleModel[module] || {};
		return module;
	},
	statusCode: {
		success: 200,
		unauthorized: 401
	},
	printHospital: (hospitalsFilterData, parentFilters) => {
		let toReturn = [];
		for (let i = 0; i < hospitalsFilterData.length; i++) {
			for (let j = 0; j < parentFilters.hospitalId?.length; j++) {
				if (hospitalsFilterData[i].value === parentFilters.hospitalId[j]) {
					toReturn.push(hospitalsFilterData[i].label);
				}
			}
		}
		return toReturn.join(' , ');
	},
	printRoom: (roomsFilterData, parentFilters) => {
		let toReturn = [];
		for (let i = 0; i < roomsFilterData.length; i++) {
			for (let j = 0; j < parentFilters.roomId?.length; j++) {
				if (roomsFilterData[i].value === parentFilters.roomId[j]) {
					toReturn.push(roomsFilterData[i].label);
				}
			}
		}
		return toReturn.join(', ');
	},
	printSensor: (sensorFilterData, parentFilters) => {
		let toReturn = [];
		for (let i = 0; i < sensorFilterData.length; i++) {
			for (let j = 0; j < parentFilters.sensorId?.length; j++) {
				if (sensorFilterData[i].value === parentFilters.sensorId[j]) {
					toReturn.push(sensorFilterData[i].label);
				}
			}
		}
		return toReturn.join(', ');
	},
	timeConversion:(secs)=>{
		let sec_num = parseInt(secs, 10)
		let hours   = Math.floor(sec_num / 3600)
		let minutes = Math.floor(sec_num / 60) % 60
		let seconds = sec_num % 60
	
		return [hours,minutes,seconds]
			.map(v => v < 10 ? "0" + v : v)
			.filter((v,i) => v !== "00" || i > 0)
			.join(":")
	},
    hasChildren: (item) => {
		const children = item.children;

		if (children === undefined) {
			return false;
		}

		if (children.constructor !== Array) {
			return false;
		}

		if (children.length === 0) {
			return false;
		}

		return true;
	},

	isObjEmpty: (obj) => {
		return Object.keys(obj).length === 0;
	}

};

export default util;
