import { roles } from './util.js';

const config = {
    title: 'User',
    defaultSort: 'firstName',
    module: 'user',
    columns: [
        { field: "firstName", flex: 1, header: "First Name", required: true, minLength: 3, maxLength: 15, hideable: false },
        { field: "lastName", flex: 1, header: "Last Name", required: true, minLength: 3, maxLength: 15 },
        { field: "email", flex: 1, header: "Email", validate: 'isEmail', minLength: 10, maxLength: 50 },
        { field: "password", flex: 1, type: 'password', hideable: true },
        { field: "hospitalId", width: 200, header: "Hospital", lookup: 'hospital', relationType: 'many', required: true },
        {
            field: "roleId", width: 120, header: "Role", lookup: 'role', required: true, hideConfig: {
                onHide: function ({ activeRecord, column, userContext }) {
                    let roleLookup = activeRecord?.lookups?.role ?? [];
                    if (userContext.role !== roles.superAdmin) {
                        roleLookup = roleLookup.filter(lookup => lookup.label !== roles.superAdmin);
                        activeRecord.lookups.role = roleLookup;
                    }
                    return { isHide: false, column }
                }
            }
        },
        { field: "isActive", header: "Active", type: 'boolean', defaultValue: true },
        { field: "lastLogin", width: 200, header: "Last Login", type: 'date', time: true, readOnly: true, isLocal: true, defaultValue: new Date() }
    ]
};

export default config;