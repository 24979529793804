import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from './userContext';
import { getList, saveRecord } from '../Components/crud-helper';
import { api as apiDefs } from '../config/authConfig';
import models from '../config/models';
const DataFiltersContext = React.createContext([[], () => {}]);
const model = models.filter((ele) => ele?.name === 'dataFilter')[0];
const modelConfig = { ...model };
modelConfig.readOnly = true;
const DataFiltersProvider = ({ children }) => {
	const [dataFilters, setDataFilters] = useState([]);
	const [userContext] = useContext(UserContext);
	useEffect(() => {
		getList({
			page: 0,
			pageSize: 50,
			sortModel: [],
			gridColumns: [],
			parentFilters: {
				userId: userContext?.id
			},
			api: `${apiDefs.base}/dataFilter`,
			setIsLoading: () => {},
			setData: (data) => {
				setDataFilters(Array.isArray(data.rows) ? data.rows : []);
			}
		});
	}, []);

	return <DataFiltersContext.Provider value={[dataFilters, setDataFilters]}>{children}</DataFiltersContext.Provider>;
};

const useDataFilter = (defaultFilter = {}, path = null) => {
	const [dataFilters, setDataFilters] = useContext(DataFiltersContext);
	const location = useLocation();
	const [userContext] = useContext(UserContext);
	const pathname = path || location.pathname;
	const dataFilterIndex = dataFilters.findIndex((entry) => entry.pathname === pathname);
	const dataFilter = useMemo(
		() => ({
			filter: {
				...defaultFilter
			},
			pathname,
			userId: userContext?.id,
			...dataFilters[dataFilterIndex]
		}),
		[dataFilters, dataFilterIndex, pathname, userContext?.id]
	);
	const upsertDataFilter = (values) => {
		const nextDataFilters = JSON.parse(JSON.stringify(dataFilters));
		const nextDataFilter = { ...dataFilter, filter: values };

		if (dataFilterIndex === -1) {
			nextDataFilters.push(nextDataFilter);
		} else {
			nextDataFilters[dataFilterIndex] = nextDataFilter;
		}
		if (!!modelConfig?.api) {
			saveRecord({ api: modelConfig.api, id: nextDataFilter?.id, values: nextDataFilter });
		}
		setDataFilters(nextDataFilters);
	};
	return [dataFilter.filter, upsertDataFilter];
};

export { DataFiltersContext, DataFiltersProvider, useDataFilter };
