const config = {
    title: 'Mqtt Client',
    defaultSort: 'code',
    module: 'mqttClient',
    columns: [
        { field: "code", width: 150, header: "Code", required: true, minLength: 2, maxLength: 10, hideable: false },
        { field: "hospitalId", flex: 1, header: "Hospital", lookup: "hospital", required: true },
        { field: "username", flex: 1, header: "Username", required: true, minLength: 2, maxLength: 30 },
        { field: "password", width: 150, label: "Password", type: "password", requiredIfNew: true, minLength: 10, maxLength: 30, generator: 'password' },
        { field: "confirmPassword", width: 150, store: false, label: "Confirm Password", type: "password", requiredIfNew: true, validate: 'compare:password' },
        { field: "isActive", header: "Active", type: 'boolean', defaultValue: true, width: 150, align: 'left', headerAlign: 'left' }
    ]
};

export default config;
