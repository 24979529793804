import { useCallback, useContext } from 'react';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/styles';

import { UserContext } from '../../contexts/userContext';

function CardComponent(props) {
    const navigate = useNavigate();
    const [userContext] = useContext(UserContext);
    const permission = ['Super Admin', 'Admin', 'User Limited'].includes(userContext.role);
    const { title, value, redirectTo, total, children, ...rest } = props;
    const { palette } = useTheme();

    const headerSubTitle = (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ fontWeight: 'bold', fontSize: 20 }}>{`${typeof value !== "undefined"? value : ""}${typeof value !== "undefined" && typeof total !== "undefined"? "/" : "" }${typeof total !== "undefined"? total : ""}`}</div>
        </div>
    );
    
    const navigatePath = useCallback((pathName) => {
        if (permission) {
            navigate(pathName);
        }
    });

    return (
        <Grid item lg={4} md={4} sm={6} xs={12} {...rest}>
            <Link color="primary" style={{ textDecoration: 'none' }} onClick={() => navigatePath(redirectTo)}>
                <Paper
                    sx={{
                        py: 1,
                        px: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        position: "relative",
                        backgroundColor: palette.primary.medium,
                        borderRadius: "0.7em"
                    }}
                >
                    <Typography
                        component="h2"
                        variant="h6"
                        gutterBottom fontSize={16} fontWeight="300"
                        color={palette.primary.text.dark}
                        className="print-show">
                        {title}
                    </Typography>
                    <div style={{
                        display: "flex", alignContent: "center", alignItems: "center",
                        position: "relative"
                    }}>
                        <p
                            className="print-show"
                            style={{
                                color: palette.primary.text.dark,
                                position: "absolute", left: "50%",
                                transform: "translate(-50%, -50%)", top: "35%",
                                zIndex: 2
                            }}>{headerSubTitle}</p>
                        {children}
                    </div>
                </Paper>
            </Link>
        </Grid >
    );
}

export default CardComponent;
