const config = {
    title: 'Telemetry Summary',
    defaultSort: 'Date desc',
    api: 'telemetry/summary',
    readOnly: true,
    module: 'telemetrySummary',
    columns: [
        { field: "sensorId", flex: 1, header: "Sensor", lookup: 'sensor', required: true, hideable: false },
        { field: "roomId", flex: 1, header: "Room", lookup: 'room', required: true },
        { field: "hospitalId", flex: 1, header: "Hospital", lookup: 'hospital', required: true },
        { field: "Date", width: 120, header: "Date", type: "date", showDateOnly: true },
        { field: "dayRecords", flex: 1, header: "Readings", type: "number" },
        { field: "min", width: 150, header: "Min", minValue: -100, maxValue: 100, type: "number" },
        { field: "max", width: 150, header: "Max", minValue: -100, maxValue: 100, type: "number" },
        { field: "outOfRange", width: 150, header: "Out-of-range", type: "number" }
    ]
};

export default config;
