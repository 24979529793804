const config = {
    title: 'Alert Recipient',
    defaultSort: 'createdAt desc',
    linkColumn: 'alertId',
    module: 'alertRecipient',
    columns: [
        { field: "alertId", header: "Alert Id", width: 150, readOnly: true },
        { field: "recipientEmail", header: "Recipient Email", width: 200 , readOnly: true},
        { field: "tries", header: "Tries", width: 80, type: 'number', minValue: 0, maxValue: 999, readOnly: true },
        { field: "sentOn", header: "Sent On", width: 200, type: "date", time: true, readOnly: true },
        { field: "createdAt", header: "Created At", width: 200, type: "date",  time: true, readOnly: true },
        {
            field: "acknowledged", width: 120, header: "Status", headerAlign: 'left', align: 'left', type: "boolean", sortable: false, filterable: false, renderCell: (params) => {
                const value = params.row.acknowledged;
                let toReturn = 'Unread';
                if (value) {
                    toReturn = 'Read';
                }
                return toReturn
            },
            hideConfig: {
                onHide: () => { return { isHide: true } }
            }
        },
        { field: "acknowledgedDateTime", width: 300, header: "Acknowledgement Date/Time", type: "date", sortable: false, filterable: false, readOnly: true },
        { field: "comment", width: 150, header: "Comment", sortable: false, filterable: false },
        { field: "commentDateTime", width: 250, header: "Comment Date/Time", type: "date", sortable: false, filterable: false, readOnly: true },
		{ field: "isEscalation", width: 100, header: "Escalation", type: "boolean", sortable: false, filterable: false, readOnly: true },
		{ field: "escalationLevel", width: 100, header: "Escalation Level", type: "number", sortable: false, filterable: false, readOnly: true , defaultValue:0 , minValue: 0, maxValue: 5}
    ],
    parentRelation: 'alertDefinitionSensorId'
};

export default config;