import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Container, Grid, Typography, Paper, Box } from '@mui/material';
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Label, ResponsiveContainer, BarChart } from 'recharts';
import { getList } from '../Components/crud-helper';
import { api as apiDefs } from '../config/authConfig';
import { useTheme } from '@mui/styles';
import Filters from '../Components/Filters';
import { dateFormats, sensorProfileIds } from 'dips-models';
import { UserContext } from '../contexts/userContext';
import { useDataFilter } from '../contexts/dataFiltersContext';
import util from '../util';
const { reportDurationDateFormat, reportCreatedDateFormat } = dateFormats;
const dateFormat = 'YYYY-MM-DD';
const profileIdForDoorSensor = sensorProfileIds.door;
const baseLookupParams = [
	{ lookupType: 'hospital' },
	{ lookupType: 'room' },
	{
		lookupType: 'sensor',
		where: { '$sensor.telemetryDescription$': 'Magnet' }
	}
];

function DoorMovementAverage() {
	const [userContext] = React.useContext(UserContext);
	const [chartData, setChartData] = useState({ data: [] });
	const [hospitalsFilterData, setHospitalsFilterData] = useState([]);
	const [roomsFilterData, setRoomsFilterData] = useState([]);
	const [sensorFilterData, setSensorFilterData] = useState([]);
	const [label, setLabel] = useState({ roomLabel: '', sensorLabel: '', hospitalLabel: '' });
	const { palette } = useTheme();
	const [parentFilters, setParentFilters] = useDataFilter({
		ProfileId: profileIdForDoorSensor,
		Date: {
			operator: 'between',
			startDate: dayjs().subtract(6, 'day').format(dateFormat),
			endDate: dayjs().format(dateFormat)
		}
	});

	const extraParams = {
		report: 'DoorMovementAverage',
		indexName: `telemetry-${new Date().toISOString().substring(0, 7)}-door`,
		valueField: 'duration'
	};
	const printHospitalVal = util.printHospital(hospitalsFilterData, parentFilters);
	useEffect(() => {
		let parentF = { ...parentFilters };
		getList({
			page: 0,
			pageSize: 10,
			sortModel: [],
			gridColumns: [],
			grid: 'doorMovementAverage',
			api: `${apiDefs.base}/telemetry/summary`,
			setIsLoading: () => { },
			setData: (response) => {
				const { rows } = response;
				setChartData({ data: Array.isArray(rows) ? rows: [] });
			},
			modelConfig: { formDef: [] },
			parentFilters: {
				...parentF
			},
			action: 'report',
			extraParams: { ...extraParams, report: 'DoorMovementAverageStats' }
		});
	}, [parentFilters]);
	const CustomTooltip = ({ active, payload }) => {
		if (active && payload && payload.length) {
			const finalPayload = payload[0].payload;
			const { date, doorCountAvg, sensorCount } = finalPayload;
			return (
				<div
					style={{
						backgroundColor: palette.primary.medium,
						border: `1px solid ${palette.primary.medium}`,
						color: palette.primary.text.dark,
						boxShadow: `0px 0px 2px ${palette.primary.text.dark}`,
						padding: '7px 20px 7px 4px'
					}}
				>
					<div style={{ color: palette.primary.text.dark }}>
						<p>{date}</p>
						<p>Average: {doorCountAvg}</p>
						<p>Sensors: {sensorCount}</p>
					</div>
				</div>
			);
		}

		return null;
	};
	return (
		<Container
			maxWidth="lg"
			sx={{
				mt: 5,
				mb: 4,
				backgroundColor: palette.primary.light_1,
				padding: '26px 20px 10px 0px'
			}}
		>
			<Filters
				setParentFilters={setParentFilters}
				parentFilters={parentFilters}
				setLabel={setLabel}
				label={label}
				showSensorTypeFilter={false}
				lookupParams={baseLookupParams}
				hospitalsFilterData={hospitalsFilterData}
				setHospitalsFilterData={setHospitalsFilterData}
				roomsFilterData={roomsFilterData}
				setRoomsFilterData={setRoomsFilterData}
				sensorFilterData={sensorFilterData}
				setSensorFilterData={setSensorFilterData}
			/>
			<Grid row>
				<Grid spacing={3} sx={{ mt: 3 }}>
					<Box className="only-print-content print-pd0" sx={{ p: 1 }}>
						<Box display="flex" justifyContent="center" alignItems="center" mb={1}>
							<img src={`data:image/png;base64,${userContext.appLogo2}`} style={{ width: 200, height: 40 }} alt={`${userContext?.docTitle}`} />
						</Box>
						<Box
							sx={{ fontWeight: 'bold', textDecoration: 'underline' }}
							mb={5}
							pt={2}
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							Door Movement Average Report
						</Box>
						<Box className="report-header-container">
							<Box>Hospital: {printHospitalVal}</Box>
							<Box>
								Dates: {dayjs(parentFilters.Date.startDate).format(reportDurationDateFormat)} to{' '}
								{dayjs(parentFilters.Date.endDate).format(reportDurationDateFormat)}
							</Box>
							<Box>Created: {dayjs().format(reportCreatedDateFormat)}</Box>
						</Box>
					</Box>
					<Paper
						sx={{
							displasensor2: 'flex',
							flexDirection: 'column',
							backgroundColor: 'transparent',
							gap: '10px'
						}}
					>
						<Typography
							style={{
								backgroundColor: 'transparent',
								fontWeight: '300',
								color: palette.primary.text.dark
							}}
							component="h2"
							variant="h6"
							color="inherit"
							textAlign="left"
						>
							Door Movement Average per Day Chart - {printHospitalVal ? printHospitalVal : 'All'}
						</Typography>
						<ResponsiveContainer width="100%" height={420}>
							<BarChart
								width={800}
								height={300}
								data={chartData.data}
								margin={{
									top: 20,
									right: 30,
									left: 20,
									bottom: 5
								}}
								style={{
									backgroundColor: palette.primary.light_1,
									marginBottom: 30,
									fontSize: '12px'
								}}
							>
								<CartesianGrid strokeDasharray="1 1" />
								<XAxis dataKey="date" allowDataOverflow={false} tick={{ fill: palette.primary.text.dark }} />
								<YAxis
									allowDataOverflow={false}
									// width={100}
									domain={['auto', 'auto']}
									tick={{ fill: palette.primary.text.dark }}
									width={150}
								>
									<Label
										value={'Average Door Count'}
										angle={-90}
										style={{
											textAnchor: 'middle',
											fill: palette.primary.text.dark
										}}
									/>
								</YAxis>
								<Tooltip
									content={<CustomTooltip payload={chartData.data} />}
									cursor={false}
								/>
								<Bar
									dataKey={`doorCountAvg`}
									stackId="a"
									isDuration={false}
									stroke={palette.primary.gridLines}
									unit={'seconds'}
									fill={palette.primary.gridLines}
								/>

							</BarChart>
						</ResponsiveContainer>
					</Paper>
				</Grid>
			</Grid>
		</Container>
	);
}

export default DoorMovementAverage;
