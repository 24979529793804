import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'; 
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTheme, makeStyles } from '@mui/styles';

const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
        color: "#aaa"
    },
    select: {
        backgroundColor: theme.palette.primary.light_3,
        color: theme.palette.primary.text.dark,
        "& li": {
            fontWeight: "300",
            fontSize: "14px",
            borderBottom: `1px solid ${theme.palette.primary.text.light}`
        }
    }
}));

const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
};

function SelectComponent(props) {
    const { label, id, options, handleChange, selectedValue, ...others } = props;
    const { palette } = useTheme();
    const classes = usePlaceholderStyles();

    return (
        <Box sx={{
            minWidth: 150
        }}>
            <InputLabel
                sx={{
                    color: palette.primary.text.dark,
                    fontSize: 13, opacity: 0.5
                }}
                id={id}
            >{label}</InputLabel>
            <FormControl
                size="small"
                variant="standard"
                fullWidth>
                <Select
                    IconComponent={KeyboardArrowDownIcon}
                    sx={{
                        borderBottom: `1px solid ${palette.primary.text.medium}`,
                        mt: 0.5,
                        color: palette.primary.text.dark,
                        "& .MuiSvgIcon-root": {
                            color: palette.primary.text.dark,
                        },
                        '& label.Mui-focused': {
                            color: palette.primary.text.dark,
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: 'yellow',
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: palette.primary.text.dark,
                            },
                            '&:hover fieldset': {
                                borderColor: palette.primary.text.dark,
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'yellow',
                            }
                        },

                    }}
                    key={id}
                    value={selectedValue}
                    label={label}
                    onChange={handleChange}
                    displayEmpty
                    renderValue={selectedValue?.length ? undefined : () => <Placeholder>Select</Placeholder>}
                    {...others}
                    MenuProps={{
                        classes: {
                            list: classes.select
                        }
                    }}
                >
                    {options && options.map((element) => {
                        return (
                            <MenuItem
                                key={element.value}
                                value={element.value}>
                                {element.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
}

export default SelectComponent;
