import * as utility from './util.js';
import alertDefinition from './alertDefinition.js';
import alertDefinitionSensor from './alertDefinitionSensor.js';
import alertRecipient from './alertRecipient.js';
import alertsHistory from './alertsHistory.js';
import cases from './case.js';
import dashboardAlert from './DashboardAlert.js';
import dataFilter from './dataFilter.js';
import doorMovementAverage from './doorMovementAverage.js';
import doorMovementReport from './doorMovement.js';
import doorReport from './door.js';
import hospital from './hospital.js';
import humidityDailySummary from './humidityDailySummary.js';
import humidityHistory from './humidityHistory.js';
import mqttClient from './mqttClient.js';
import myAlertsHistory from './myAlertsHistory.js';
import notifications from './notifications.js';
import role from './role.js';
import roleModules from './roleModules.js';
import room from './room.js';
import sensor from './sensor.js';
import sensorType from './sensorType.js';
import telemetry from './telemetry.js';
import telemetryHelper from './helpers/telemetry.js';
import telemetrySummary from './telemetrySummary.js';
import temperatureDailySummary from './temperatureDailySummary.js';
import temperatureHistoryReport from './temperatureHistory.js';
import user from './user.js';

class ModelConfigList extends Array {
	get(name) {
		return this.find((m) => m.name === name);
	}
}

const helpers = {
	telemetry: telemetryHelper
};

const lookupTypes = [];

const modelConfigs = new ModelConfigList(
	role,
	sensorType,
	user,
	hospital,
	room,
	sensor,
	mqttClient,
	telemetry,
	telemetrySummary,
	cases,
	temperatureHistoryReport,
	doorReport,
	doorMovementReport,
	dashboardAlert,
	alertDefinition,
	alertDefinitionSensor,
	alertRecipient,
	notifications,
	myAlertsHistory,
	humidityDailySummary,
	doorMovementAverage,
	temperatureDailySummary,
	humidityHistory,
	alertsHistory,
	dataFilter
);

for (const model of modelConfigs) {
	const defaultModelName = utility.toCamelCase(model.title);
	if (!model.controllerType) {
		model.controllerType = model.api ? 'rest' : 'default';
	}
	if (!model.api) {
		model.api = defaultModelName;
	}
	if (!model.name) {
		model.name = defaultModelName;
	}
	// ignore for custom/ elastic controller types etc.
	if (model.controllerType === 'default') {
		lookupTypes.push(model.name);
	}
}
 
const dateFormats = {
	dateTimeFormat: 'MM-DD-YYYYTHH:mm:ss',
	dateTimeFormatAMPM: 'MM-DD-YYYY  hh:mm A',
	dateTimeFormatSecondAMPM: 'MM-DD-YYYY hh:mm:ss A',
	timeFormat: 'hh:mm A',
	date: 'MM-DD-YYYY',
	dateFormatHM: 'MM-DD-YYYY HH:mm',
	reportDurationDateFormat: 'MMM DD, YYYY',
	reportCreatedDateFormat: 'dddd MMM DD, YYYY'
};

const sensorProfileIds = {
	humidity: 43,
	temperature: 2,
	door: 9
};

const sensorDescription = {
	humidity: "Humidity",
	temperature: "Temperature",
	door: "Door"
};

export {
	user,
	hospital,
	room,
	sensor,
	role,
	sensorType,
	lookupTypes,
	mqttClient,
	helpers,
	telemetry,
	telemetrySummary,
	cases,
	temperatureHistoryReport,
	doorReport,
	doorMovementReport,
	dashboardAlert,
	alertDefinition,
	alertDefinitionSensor,
	alertRecipient,
	roleModules,
	dateFormats,
	notifications,
	myAlertsHistory,
	humidityDailySummary,
	doorMovementAverage,
	temperatureDailySummary,
	humidityHistory,
	sensorProfileIds,
	sensorDescription,
	alertsHistory,
	dataFilter
};
export default modelConfigs;
