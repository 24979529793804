import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { useTheme } from '@mui/styles';

const CustomPieChart = (props) => {
    const { data=[], color, dataKey="value" } = props;
    const { palette } = useTheme();

    return (
        <ResponsiveContainer
            className="card-img-bottom overflow-hidden"
            width={"100%"} height={140}>
            <PieChart width={90} height={80}>
                <Pie
                    width={30}
                    height={30}
                    data={data}
                    cx="50%"
                    cy="45%"
                    labelLine={false}
                    outerRadius={60}
                    innerRadius={43}
                    stroke="none"
                    fill={color || palette.primary.light_4}
                    dataKey={dataKey}
                    paddingAngle={0.5}
                    isAnimationActive={true}
                    tooltipType='none'
                >
                    {data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                        />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer >
    );
};

export default CustomPieChart;
