
const hideField = ({ activeRecord, formValues, column }, rename) => {
    const { lookups = {} } = activeRecord || {};
    const sensroType = formValues && formValues["sensorTypeId"];
    const { sensorType = [] } = lookups;
    const doorSensorType = sensorType.filter(lookup => lookup.value === sensroType)[0] || {};
    column.header = doorSensorType.isDuration ? "Duration" : (rename ? "Max" : "Min");
    return { isHide: rename ? false : doorSensorType.isDuration, column };
}

const config = {
    title: 'Sensor',
    displayField: 'name',
    defaultSort: 'code',
    module: 'sensor',
    securityFilter: {
        hospitalId: "room:hospital"
    },
    columns: [
        { field: "code", width: 120, header: "Code", required: true, minLength: 3, maxLength: 20, hideable: false },
        { field: "sensorTypeId", header: "Sensor Type", lookup: "sensorType", required: true, width: 150 },
        { field: "roomId", header: "Room", lookup: "room", required: true, width: 200 },
        { field: "name", header: "Name", required: true, minLength: 5, maxLength: 30, width: 250 },
        {
            field: "minThreshold", header: "Min", type: 'number', minValue: -100, maxValue: 100, decimal: false,
            hideConfig: {
                onHide: function ({ activeRecord, formValues, column }) {
                    return hideField({ activeRecord, formValues, column }, false)
                }
            }
        },
        {
            field: "maxThreshold", header: "Max", type: 'number', minValue: -100, maxValue: 100, decimal: false,
            hideConfig: {
                onHide: function ({ activeRecord, formValues, column }) {
                    return hideField({ activeRecord, formValues, column }, true)
                }
            }
        },
        { field: "isActive", header: "Active", type: 'boolean', defaultValue: true },
        { field: "telemetryReceivedAt", header: "Received At", type: 'date', disabled: true, width: 200, time: true },
        { field: "telemetryDate", header: "Date", type: 'date', disabled: true, width: 200, time: true },
        { field: "telemetryProfileId", header: "ProfileId", type: 'number', disabled: true, minValue: -1000, maxValue: 1000 },
        { field: "telemetryState", header: "State", type: 'number', disabled: true, minValue: -1000, maxValue: 1000 },
        { field: "telemetryInRange", header: "InRange", type: 'boolean', disabled: true },
        { field: "telemetrySignalStrength", header: "Signal Strength", type: 'number', decimal: true, disabled: true, minValue: -1000, maxValue: 1000 },
        { field: "telemetryData", header: "Data", type: 'number', decimal: true, disabled: true, minValue: -1000, maxValue: 1000 },
        { field: "telemetryDescription", header: "Description", disabled: true, width: 200 },
        { field: "telemetryVoltage", header: "Voltage", type: 'number', decimal: true, disabled: true, minValue: -1000, maxValue: 1000 }
    ],
    relations: [
        'telemetry',
        'telemetrySummary'
    ]
};

export default config;
