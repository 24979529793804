const config = {
	title: 'Door Report',
	defaultSort: 'Date desc',
	api: 'telemetry/summary',
	controllerType: 'elastic',
	readOnly: true,
	module: 'door',
	columns: [
		{
			field: 'sensorId',
			flex: 1,
			header: 'Sensor',
			lookup: 'sensor',
			required: true,
			hideable: false,
			sortable: false,
			filterable: false
		},
		{
			field: 'sensorCode',
			flex: 1,
			header: 'Sensor Code',
			type: 'string',
			readOnly: true,
			sortable: false,
			filterable: false
		},
		{
			field: 'Date',
			flex: 1,
			header: 'Date',
			type: 'date',
			time: false,
			showDateOnly: true,
			isUtc: true,
			isLocal: false
		},
		{ field: 'duration', flex: 1, header: 'Duration', type: 'number', filterable: false },
		{ field: 'count', flex: 1, header: 'Open Count', type: 'number', filterable: false }
	]
};

export default config;
