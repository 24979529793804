import { Container, Grid, Paper, Divider } from '@mui/material';
import { React, useState, useEffect, useContext } from 'react';
import { useTheme } from '@mui/styles';
import dayjs from 'dayjs';

import { useDataFilter } from '../contexts/dataFiltersContext';
import { UserContext } from '../contexts/userContext';
import Filters from '../Components/Filters';
import FormBase from './form-base';
import models from '../config/models';

const sensorModel = models.filter((ele) => ele.title === 'Sensor')[0];
const sensorModelDashboard = { ...sensorModel };

const caseModel = models.filter((ele) => ele.title === 'Case')[0];
const caseModelDashboard = { ...caseModel };
caseModelDashboard.readOnly = true;
const dateFormat = 'YYYY-MM-DD'; //TODO: Need to discuss with the Internal team, as elastic support only YYYY-MM-DD format

function SystemHealth() {
	const [userContext] = useContext(UserContext);
	const isUserLimited = ['User Limited'].includes(userContext.role);
	const { palette } = useTheme();
	const [parentFilters, setParentFilters] = useDataFilter({
		Date: {
			operator: 'between',
			startDate: dayjs().subtract(6, 'day').format(dateFormat),
			endDate: dayjs().format(dateFormat)
		}
	});
	const [hospitalsFilterData, setHospitalsFilterData] = useState([]);
	const [roomsFilterData, setRoomsFilterData] = useState([]);
	const [sensorFilterData, setSensorFilterData] = useState([]);

	return (
		<Container
			maxWidth="xl"
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '40px',
				mt: 3,
				mb: 4,
				pt: 2,
				backgroundColor: palette.primary.light_1,
				padding: '26px 20px 10px 0px'
			}}
		>
			<Filters
				setParentFilters={setParentFilters}
				parentFilters={parentFilters}
				hospitalsFilterData={hospitalsFilterData}
				setHospitalsFilterData={setHospitalsFilterData}
				roomsFilterData={roomsFilterData}
				setRoomsFilterData={setRoomsFilterData}
				sensorFilterData={sensorFilterData}
				setSensorFilterData={setSensorFilterData}
			/>

			{!isUserLimited && (
				<Grid row>
					<Grid spacing={3}>
						<Paper
							sx={{
								display: 'flex',
								flexDirection: 'column'
							}}
						>
							<FormBase
								title="Sensor Grid"
								modelConfig={sensorModelDashboard}
								key={sensorModelDashboard.title}
								parentFilters={parentFilters}
								isForDashboard={true}
							/>
						</Paper>
					</Grid>
				</Grid>
			)}
			{!isUserLimited && <Divider sx={{ backgroundColor: palette.primary.text.dark, opacity: 0.5 }} />}
			<Grid row>
				<Grid spacing={3}>
					<Paper
						sx={{
							display: 'flex',
							flexDirection: 'column'
						}}
					>
						<FormBase
							title="Cases Grid"
							modelConfig={caseModelDashboard}
							key={caseModelDashboard.title}
							parentFilters={parentFilters}
							isForDashboard={true}
						/>
					</Paper>
				</Grid>
			</Grid>
			{!isUserLimited && <Divider sx={{ backgroundColor: palette.primary.text.dark, opacity: 0.5 }} />}
		</Container>
	);
}

export default SystemHealth;
