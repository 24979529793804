const CustomizedRightTick = (props) => {
	const { x, y, stroke, payload, fill } = props;
	let seconds = payload?.value ?? 0;
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = seconds % 60;

	const formattedTime = `${hours}h ${minutes}m ${remainingSeconds}s`;
	return (
		<g transform={`translate(${x},${y})`}>
			<text x={10} y={0} dx={64} textAnchor="end" fill={fill}>
				{formattedTime}
			</text>
		</g>
	);
};

export default CustomizedRightTick;

