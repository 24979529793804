const config = {
    title: 'Notifications',
    defaultSort: 'createdAt desc',
    linkColumn: 'recipientEmail',
    module: 'alertRecipient',
    readNotification: true,
    isClient: false,
    api: 'notifications',
    securityFilter: {
        hospitalId: "room:hospital"
    },
    columns: [
        { field: "alertId", width: 150, readOnly: true },
        { field: "recipientEmail", header: "Recipient Email", width: 200, readOnly: true },
        { field: "sentOn", header: "Sent On", width: 200, type: "date", time: true, filterable: false, readOnly: true },
        {
            field: "acknowledged", width: 120, header: "Acknowledged", headerAlign: 'left', align: 'left', type: "boolean", sortable: true, filterable: true, renderCell: (params) => {
                const value = params.row.acknowledged;
                let toReturn = 'Unacknowledged';
                if (value) {
                    toReturn = 'Acknowledged';
                }
                return toReturn
            },
            hideConfig: {
                onHide: ({ activeRecord, formValues, column }) => {
                    const isHide = !Boolean(activeRecord?.record?.requiresAcknowledgment);
                    column.required = !isHide;
                    return { isHide, column }
                }
            }, required: false, validate: true
        },
        { field: "sensorId", width: 250, header: "Sensor", sortable: false, filterable: false, hideConfig: { onHide: () => { return { isHide: true } } } },
        { field: "hubId", width: 150, header: "Hub Id", sortable: false, filterable: false, hideConfig: { onHide: () => { return { isHide: true } } } },
        { field: "Date", width: 200, header: "Start Time", type: "date", isUtc: true, sortable: false, filterable: false, hideConfig: { onHide: () => { return { isHide: true } } } },
        { field: "EndTime", width: 200, header: "End Time", type: "date", isUtc: true, sortable: false, filterable: false, hideConfig: { onHide: () => { return { isHide: true } } } },
        { field: "Description", width: 150, header: "Description", sortable: false, filterable: false, hideConfig: { onHide: () => { return { isHide: true } } } },
        { field: "duration", width: 130, header: "Duration (hh:mm:ss)", type: "number", sortable: false, filterable: false, hideConfig: { onHide: () => { return { isHide: true } } } },
        { field: "isOpen", width: 100, header: "Is Open?", type: "boolean", sortable: false, filterable: false, hideConfig: { onHide: () => { return { isHide: true } } } },
        { field: "inCase", width: 100, header: "In Case?", type: "boolean", sortable: false, filterable: false, hideConfig: { onHide: () => { return { isHide: true } } } },
        { field: "Data", width: 80, header: "Reading", type: "number", sortable: false, filterable: false, hideConfig: { onHide: () => { return { isHide: true } } } },
        { field: "min", width: 80, header: "Min", type: "number", sortable: false, filterable: false, hideConfig: { onHide: () => { return { isHide: true } } } },
        { field: "max", width: 80, header: "Max", type: "number", sortable: false, filterable: false, hideConfig: { onHide: () => { return { isHide: true } } } },
        { field: "createdAt", header: "Created At", width: 200, type: "date", time: true, readOnly: true, filterable: false, hideConfig: { onHide: () => { return { isHide: true } } } },
        { field: "acknowledgedDateTime", width: 300, header: "Acknowledgement Date/Time", type: "date", sortable: false, filterable: false, readOnly: true, hideConfig: { onHide: () => { return { isHide: true } } } },
        {
            field: "comment", width: 150, header: "Comment", sortable: false, filterable: false, hideConfig: {
                onHide: ({ activeRecord, formValues, column }) => {
                    const isHide = !Boolean(activeRecord?.record?.requiresComment);
                    column.required = !isHide;
                    return { isHide, column }
                }, required: false, validate: true
            }
        },
        { field: "commentDateTime", width: 250, header: "Comment Date/Time", type: "date", sortable: false, filterable: false, readOnly: true, hideConfig: { onHide: () => { return { isHide: true } } } }
    ],
    parentRelation: 'alertDefinitionSensorId'
};

export default config;