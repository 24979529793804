import { React, useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import { useTheme } from '@mui/styles';
import { Container, Grid, Paper, Divider, Box } from '@mui/material';
import { getList } from '../Components/crud-helper';
import { api as apiDefs } from '../config/authConfig';
import { dateFormats } from 'dips-models';
import { UserContext } from '../contexts/userContext';
import { useDataFilter } from '../contexts/dataFiltersContext';
import models from '../config/models';
import FormBase from './form-base';
import Filters from '../Components/Filters';
import util from '../util';

const { reportDurationDateFormat, reportCreatedDateFormat } = dateFormats;
const model = models.filter((ele) => ele.name === 'alertsHistory')[0];
const modelDashboard = { ...model };
modelDashboard.readOnly = true;
const dateFormat = 'YYYY-MM-DD'; //TODO: Need to discuss with the Internal team, as elastic support only YYYY-MM-DD format
const baseLookupParams = [{ lookupType: 'hospital' }, { lookupType: 'room' }, { lookupType: 'sensor' }];

function AlertsHistory() {
	const [userContext] = useContext(UserContext);
	const [hospitalsFilterData, setHospitalsFilterData] = useState([]);
	const [roomsFilterData, setRoomsFilterData] = useState([]);
	const [sensorFilterData, setSensorFilterData] = useState([]);
	const isUserLimited = ['User Limited'].includes(userContext.role);
	const [report, setReport] = useState({ data: [], hospitals: '' });
	const [label, setLabel] = useState({ roomLabel: '', sensorLabel: '', hospitalLabel: '' });
	const theme = useTheme();
	const [parentFilters, setParentFilters] = useDataFilter({
		Date: {
			operator: 'between',
			startDate: dayjs().subtract(6, 'day').format(dateFormat),
			endDate: dayjs().format(dateFormat)
		}
	});
	const extraParams = {
		report: 'AlertsHistory'
	};

    const printHospitalVal=util.printHospital(hospitalsFilterData,parentFilters);
	useEffect(() => {
		let parentF = { ...parentFilters };
		getList({
			page: 0,
			pageSize: 10,
			sortModel: [],
			gridColumns: [],
			api: `${apiDefs.base}/telemetry/summary`,
			setIsLoading: () => { },
			setData: (data) => {
				if (data.rows && data.rows.length > 0) {
					data.rows.sort(function (a, b) {
						return new Date(a.Date) - new Date(b.Date);
					});
					let dataEntries = data.rows.map((item) => {
						return { date: dayjs.utc(item.Date).format(dateFormat), Duration: item.range, Count: item.count };
					});
					setReport((prevState) => ({ ...prevState, data: dataEntries, hospitals: data.hospitals.join(',') }));
				} else {
					setReport((prevState) => ({ ...prevState, data: [], hospitals: '' }));
				}
			},
			modelConfig: { formDef: [] },
			parentFilters: {
				...parentF
			},
			action: 'report',
			extraParams: extraParams
		});
	}, [parentFilters]);

	return (
		<Container
			maxWidth="xl"
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '40px',
				mt: 3,
				mb: 4,
				pt: 2,
				backgroundColor: theme.palette.primary.light_1,
				padding: '26px 20px 10px 0px'
			}}
		>
			<Filters
				setParentFilters={setParentFilters}
				parentFilters={parentFilters}
				setLabel={setLabel}
				label={label}
				showSensorTypeFilter={false}
				lookupParams={baseLookupParams}
				hospitalsFilterData={hospitalsFilterData}
				setHospitalsFilterData={setHospitalsFilterData}
				roomsFilterData={roomsFilterData}
				setRoomsFilterData={setRoomsFilterData}
				sensorFilterData={sensorFilterData}
				setSensorFilterData={setSensorFilterData}
			/>
			<Grid row>
				<Grid spacing={3} sx={{ mt: 3 }}>
					<Box className="only-print-content print-pd0" sx={{ p: 1 }}>
						<Box display="flex" justifyContent="center" alignItems="center" mb={1}>
							<img src={`data:image/png;base64,${userContext.appLogo2}`} style={{ width: 200, height: 40 }} alt={`${userContext?.docTitle}`} />
						</Box>
						<Box
							sx={{ fontWeight: 'bold', textDecoration: 'underline' }}
							mb={5}
							pt={2}
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							Alerts History Report
						</Box>
						<Box className="report-header-container">
							<Box>Hospital: {printHospitalVal}</Box>
							<Box>
								Dates: {dayjs(parentFilters.Date.startDate).format(reportDurationDateFormat)} to{' '}
								{dayjs(parentFilters.Date.endDate).format(reportDurationDateFormat)}
							</Box>
							<Box>Created: {dayjs().format(reportCreatedDateFormat)}</Box>
						</Box>
					</Box>
				</Grid>
			</Grid>
			<Grid row>
				<Grid spacing={3} sx={{ mt: 7 }}>
					<FormBase
						title={`${model.title} - ${printHospitalVal?printHospitalVal:'All'}`}
						modelConfig={modelDashboard}
						key={modelDashboard.title}
						parentFilters={parentFilters}
						action={'report'}
						extraParams={extraParams}
					/>
				</Grid>
			</Grid>
		</Container>
	);
}

export default AlertsHistory;
