const toCamelCase = function (str) {
    str = str[0].toLowerCase() + str.substr(1);
    return str.replace(/([-_][a-z])/g, function (group) {
        return group.toUpperCase().replace('-', '').replace('_', '');
    }).replace(/ /g, '');
};

const roles = {
    superAdmin: 'Super Admin',
    admin: 'Admin'
}

export { toCamelCase, roles };