import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

/**
 * Copyright component displaying copyright information.
 * @param {Object} textProps - Additional props for the Typography component.
 * @param {Object} linkProps - Additional props for the anchor (a) element.
 * @returns {JSX.Element} - React component.
 */
const Copyright = ({ textProps = {}, linkProps = {} }) => {

	const [copyRightData, setCopyRightData] = useState(null);

	// Fetch copyright data from the server on component mount.
	useEffect(() => {
		const fetchData = async () => {
			try {
				let res = await fetch('/api/config/copyright');
				
				res = await res.json();

				if (res) {
					setCopyRightData(res);
				}
			} catch (error) {
				// Handle any errors that occur during the fetch.
				console.error('Error fetching copyright data:', error);
			}
		};

		fetchData();
	}, []);

	return (
		<Typography variant="body2" color="text.secondary" align="center" {...textProps}>
			{'Copyright © '}
			{/* Display the copyright link if data is available. */}
			<a href={copyRightData?.linkURL} {...linkProps}>
				{copyRightData?.linkText}
			</a>{' '}
			{/* Display the current year. */}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
};

export default Copyright;

