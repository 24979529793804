import Button from '@mui/material/Button';
import {
	DataGridPro,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	gridClasses,
	GridToolbarFilterButton,
	GridToolbarExportContainer,
	getGridDateOperators,
	getGridNumericOperators
} from '@mui/x-data-grid-pro';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { useMemo, useEffect, useContext } from 'react';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import { useTheme, makeStyles } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import util from '../../util';
import Loader from '../loader';
import ExportMenuItem from './export-menu-item';
import { exportRecord, getList, getRecord, saveRecord } from '../crud-helper';
import { UserContext } from '../../contexts/userContext';
import { dateFormats } from 'dips-models';

const { dateFormatHM: dateFilterFormat, date: dateFormat } = dateFormats;
const defaultPageSize = 10;
const sortRegex = /(\w+)( ASC| DESC)?/i;

const useStyles = makeStyles((theme) => {
	return {
		root: {
			'& .MuiPaper-root': {
				backgroundColor: `${theme.palette.primary.light_2}!important`,
				color: theme.palette.primary.text.dark
			},
			'& .MuiPopover-paper': {
				backgroundColor: 'red!important',
				color: `${theme.palette.primary.text.dark}!important`
			}
		},
		header: {
			backgroundColor: theme.palette.primary.light_7,
			fontSize: '15px',
			color: theme.palette.primary.text.medium,
			paddingBottom: 0,
			'& .MuiPaper-root': {
				backgroundColor: `${theme.palette.primary.light_2}!important`,
				color: theme.palette.primary.text.medium
			},
			'& .MuiInputLabel-root': {
				color: theme.palette.primary.text.dark
			}
		},
		cell: {
			color: theme.palette.primary.text.medium2,
			'& .MuiDataGrid-booleanCell': {
				color: theme.palette.primary.text.dark
			},
			fontWeight: '300'
		},
		cellContent: {
			paddingBottom: 0,
			'& .MuiDataGrid-booleanCell': {
				color: theme.palette.primary.text.dark
			}
		},
		footer: {
			backgroundColor: theme.palette.primary.light_1,
			fontSize: 10,
			gap: 2.5,
			padding: '10px 18px',
			'& button': {
				color: theme.palette.primary.text.dark,
				flex: 1,
				fontWeight: '200'
			},
			'& .MuiTablePagination-root': {
				color: theme.palette.primary.text.dark
			}
		},
		menu: {
			'& .MuiPaper-root': {
				backgroundColor: `${theme.palette.primary.light_2}!important`,
				color: theme.palette.primary.text.dark
			}
		},
		panelFooter: {},
		menuList: {
			color: theme.palette.primary.text.dark
		}
	};
});

const convertDefaultSort = (defaultSort) => {
	const orderBy = [];
	if (typeof defaultSort === 'string') {
		const sortFields = defaultSort.split(',');
		for (const sortField of sortFields) {
			sortRegex.lastIndex = 0;
			const sortInfo = sortRegex.exec(sortField);
			if (sortInfo) {
				const [, field, direction = 'ASC'] = sortInfo;
				orderBy.push({ field: field.trim(), sort: direction.trim().toLowerCase() });
			}
		}
	}
	return orderBy;
};

const CustomExportButton = (props) => (
	<GridToolbarExportContainer {...props}>
		<ExportMenuItem {...props} type="pdf" />
		<ExportMenuItem {...props} type="excel" />
	</GridToolbarExportContainer>
);

let gridFilters = null;

const GridBase = ({
	useLinkColumn = true,
	modelConfig,
	columns,
	api,
	defaultSort,
	setActiveRecord,
	onSelection,
	refresh,
	parentFilters,
	parent,
	action,
	isForDashboard = false,
	extraParams,
	where,
	baseFilters,
	title,
	readNotification
}) => {
	const [pageSize, setPageSize] = useState(defaultPageSize);
	const [page, setPage] = useState(0);
	const [muiTableKey, setMuiTableKey] = useState(0);
	const [data, setData] = useState({ rowCount: 0, rows: [] });
	const [isLoading, setIsLoading] = useState(false);
	const [allColumns, setAllColumns] = useState();
	const [userContext, setUserContext] = useContext(UserContext);
	let dateValue = new Date();
	let numberValue= 0;

	const isClient = modelConfig.isClient === true ? 'client' : 'server';

	const [sortModel, setSortModel] = useState(convertDefaultSort(defaultSort || modelConfig?.defaultSort));
	const [filterModel, setFilterModel] = useState(null);
	if (filterModel !== null) {
		gridFilters = filterModel;
	}
	const { role } = userContext || {};
	const { add, export: canExport } = util.hasPermission({ role, module: modelConfig.module });
	const permission = util.hasPermission({ role, module: modelConfig.module });

	if (util.isObjEmpty(permission)) {
		window.location.href = '/';
	}

	// add actions and use dipatch for the rows

	const gridColumns = useMemo(() => {
		let finalColumns = columns || modelConfig?.gridColumns || modelConfig?.columns;
		if (parent) {
			return finalColumns.filter((column) => column.lookup !== parent && !column.hideable);
		}
		return finalColumns.filter((column) => !column.hideable);
	}, [columns, modelConfig, parent]);

	if (modelConfig.listTitle === 'Notifications') {
	    data.rows.forEach((row)=>{
            row.duration = util.timeConversion(row.duration)
		})
	}

	const fetchData = () => {
		getList({
			page,
			pageSize,
			sortModel,
			filterModel,
			api: api || modelConfig?.api,
			setIsLoading,
			setData,
			gridColumns,
			modelConfig,
			parentFilters,
			action,
			grid: modelConfig?.name,
			isForDashboard: isForDashboard,
			extraParams,
			baseFilters: modelConfig.securityFilter
		});
	};

	const openForm = (id) => {
		getRecord({ id, api: api || modelConfig?.api, setIsLoading, setActiveRecord, modelConfig, parentFilters, where });
	};

	const onCellClick = (event) => {
		const { row: record } = event;
		if (useLinkColumn && event.field === modelConfig.linkColumn && setActiveRecord) {
			openForm(record.id);
		}
		if (onSelection) {
			onSelection(record.id, record);
		}
		fetchData();
	};

	const onCellDoubleClick = (event) => {
		const { row: record } = event;
		if (setActiveRecord) {
			openForm(record.id);
		}
	};

	const handleClickOpen = () => {
		openForm(null);
	};

	const generateUUID = function () {
		// Public Domain/MIT
		var d = new Date().getTime(); //Timestamp
		var d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16; //random number between 0 and 16
			if (d > 0) {
				//Use timestamp until depleted
				r = (d + r) % 16 | 0;
				d = Math.floor(d / 16);
			} else {
				//Use microseconds since page-load if supported
				r = (d2 + r) % 16 | 0;
				d2 = Math.floor(d2 / 16);
			}
			return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
		});
	};

	function DateTimeCustomFilterComponent(props) {
		const [value, setValue] = useState(dateValue);
		const handleChange = (newValue) => {
			dateValue = new Date(newValue);
			setValue(newValue);
			onClose(dayjs(dateValue));
		};


		const onClose = (dateValue) => {

			let date = dateValue.format(dateFilterFormat);

			let dateObj = {
				items: [
					{
						...props.item,
						value: date
					}
				],
				linkOperator: 'and',
				quickFilterLogicOperator: 'and',
				quickFilterValues: []
			};
			gridFilters = dateObj

			setFilterModel(dateObj);
		};

		return (
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<MobileDateTimePicker
					label="Value"
					value={value}
					onChange={handleChange}
					renderInput={(params) => <TextField {...params} />}
					id={generateUUID()}
					closeOnSelect={false}
					onClose={onClose}
					inputFormat="MM-DD-YYYY hh:mm:ss A "
				/>
				
			</LocalizationProvider>
		);
	}

	function NumberCustomFilterComponent(props) {
		const [value, setValue] = useState(numberValue);
		const handleChange = (e) => {
			numberValue = e.target.value;
			setValue(e.target.value);
			onClose(e.target.value);
		};


		const onClose = (numberValue) => {
			let numberObj = {
				items: [
					{
						...props.item,
						value: numberValue
					}
				],
				linkOperator: 'and',
				quickFilterLogicOperator: 'and',
				quickFilterValues: []
			};
			gridFilters = numberObj

			setFilterModel(numberObj);
		};

		return (
			<TextField
            type="text"
            variant="standard"
            fullWidth
			value={value}
			label={'Number Value'}
            onChange={handleChange}
        />
		);
	}

	function DateCustomFilterComponent(props) {
		const [value, setValue] = useState(dateValue);

		const handleChange = (newValue) => {
			dateValue = new Date(newValue);
			setValue(dayjs(newValue).format(dateFormat));
			onClose(dayjs(newValue).format(dateFormat));
		};

		const onClose = (item) => {
			let date = dayjs(dateValue).format(dateFormat);
			let dateObj = {
				items: [
					{
						...props.item,
						value: date
					}
				],
				linkOperator: 'and',
				quickFilterLogicOperator: 'and',
				quickFilterValues: []
			};
			
			setFilterModel(dateObj)
			gridFilters = dateObj
		};

		return (
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker
					label="Value"
					value={value}
					onChange={handleChange}
					renderInput={(params) => <TextField {...params} />}
					id={generateUUID()}
					onClose={onClose}
					showToolbar={true}
					inputFormat="MM-DD-YYYY"
				/>
			</LocalizationProvider>
		);
	}

	const dateTimeFilterOperators = getGridDateOperators(true).map((operator) => ({
		...operator,
		InputComponent: DateTimeCustomFilterComponent
	}));

	const numberOperators = getGridNumericOperators(true).map((operator) => ({
		...operator,
		InputComponent: NumberCustomFilterComponent
	}));

	const dateFilterOperators = getGridDateOperators().map((operator) => ({
		...operator,
		InputComponent: DateCustomFilterComponent
	}));

	const updatedDateTimeFilterOperators = [];

	if (dateTimeFilterOperators.length) {
		const dateTimeFilterOperatorsObject = Object.values(dateTimeFilterOperators);
		const skipOperators = ['is', 'not', 'isEmpty', 'isNotEmpty'];
		for (const element of dateTimeFilterOperatorsObject) {
			if (skipOperators.indexOf(element.value) === -1) {
				updatedDateTimeFilterOperators.push(element);
			}
		}
	}

	const updatedDateFilterOperators = [];

	if (dateFilterOperators.length) {
		const dateFilterObject = Object.values(dateFilterOperators);
		const skipOperators = ['not', 'isEmpty', 'isNotEmpty'];
		for (const element of dateFilterObject) {
			if (skipOperators.indexOf(element.value) === -1) {
				updatedDateFilterOperators.push(element);
			}
		}
	}
	const dateColumns = [];
	const columnsDates = [];

	/* To hold columns of type number */
	const numberColumns = [];

	gridColumns &&
		gridColumns.map((ele) => {
			if (ele.type === 'date' && ele.time) {
				dateColumns.push(ele);
			}
		});
	gridColumns &&
		gridColumns.map((ele) => {
			if (ele.type === 'date' && ele.time === false) {
				columnsDates.push(ele);
			}
		});

		gridColumns &&
		gridColumns.map((ele) => {
			if (ele.type === 'number') {
				numberColumns.push(ele);
			}
		});
	gridColumns &&
		gridColumns.forEach((col, index) =>
			dateColumns.forEach((ele, i) => {
				if (col.field === ele.field) {
					gridColumns[index] = {
						...ele,
						filterOperators: updatedDateTimeFilterOperators
					};
				}
			})
		);

		gridColumns &&
		gridColumns.forEach((col, index) =>
			numberColumns.forEach((ele, i) => {
				if (col.field === ele.field) {
					/* Change operators for number columns */
					gridColumns[index] = {
						...ele,
						filterOperators: numberOperators
					};
				}
			})
		);

	gridColumns &&
		gridColumns.forEach((col, index) =>
			columnsDates.forEach((ele, i) => {
				if (col.field === ele.field) {
					gridColumns[index] = {
						...ele,
						filterOperators: updatedDateFilterOperators
					};
				}
			})
		);
	const CustomToolbar = function (props) {
		const { palette } = useTheme();

		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					backgroundColor: palette.primary.light_1,
					color: palette.primary.text.dark,
					paddingBottom: 7
				}}
			>
				{modelConfig.readOnly === true || !add || isForDashboard ? (
					<Typography
						component="h3"
						color="inherit"
						style={{
							color: palette.primary.text.dark,
							fontWeight: '500'
						}}
						textAlign="center"
						py={1.5}
					>
						{title}
					</Typography>
				) : (
					<Button
						color="inherit"
						startIcon={<AddIcon />}
						onClick={handleClickOpen}
						style={{
							color: palette.primary.text.dark,
							fontWeight: '900'
						}}
						size="small"
					>
						Add {modelConfig.title}
					</Button>
				)}

				<GridToolbarContainer {...props}>
					<GridToolbarColumnsButton sx={{ color: palette.primary.text.dark, fontWeight: '300' }} color="inherit" />
					<GridToolbarFilterButton
						sx={{
							color: palette.primary.text.dark,
							fontWeight: '300'
						}}
						color="inherit"
					/>
					{canExport && (
						<CustomExportButton
							sx={{ color: palette.primary.text.dark, fontWeight: '300' }}
							onGetData={handleExport}
							color="inherit"
						/>
					)}
				</GridToolbarContainer>
			</div>
		);
	};

	const getGridRowId = (row) => {
		return row.id || generateUUID();
	};
	const handleExport = async (type) => {
		const params = {
			limit: modelConfig?.name === 'telemetry' ? 1000 : 0,
			title: modelConfig.title,
			action: 'export',
			type,
			filterModel,
			sortModel,
			parentFilters,
			gridColumns,
			modelConfig,
			allColumns,
			setIsLoading,
			extraParams
		};
		const result = await exportRecord({
			api: modelConfig.api,
			isForDashboard: isForDashboard,
			grid: modelConfig?.name,
			baseFilters: modelConfig.securityFilter,
			...params
		});
		const fileName = type === 'excel' ? `${modelConfig.title}.xlsx` : `${modelConfig.title}.${type.toLowerCase()}`;
		return {
			fileName,
			data: result
		};
	};

	useEffect(
		fetchData,
		isClient === 'client'
			? [page, pageSize, api, refresh, gridColumns, modelConfig, parentFilters]
			: [page, pageSize, sortModel, filterModel, api, refresh, gridColumns, modelConfig, parentFilters]
	);
	// const rowCount = isClient === "client" ? undefined : data.rowCount;
	const rowCount = data.rowCount;
	const classes = useStyles();
	const { palette } = useTheme();

	const handleFilterModelChange = (model, prevState) => {
		if(model && model.items && model.items[0]){
			if(model.items[0].hasOwnProperty('value')){
				if(model.items[0].value !== undefined){
					return model
				}
				
			} else  {
				let newObj = {
					items: [
						{
							...parentFilters
						}
					],
					linkOperator: 'and',
					quickFilterLogicOperator: 'and',
					quickFilterValues: []
				};
				return newObj;
			}
		}
	

		
	}

	return (
		<>
			<Loader isLoading={isLoading} />
			<DataGridPro
				autoHeight={true}
				className="print-grid"
				getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
				sortingOrder={['desc', 'asc']}
				sx={{
					borderWidth: 0,
					borderRadius: 0,
					[`& .${gridClasses.row}.even`]: {
						backgroundColor: palette.primary.gridBackground
					},
					[`& .${gridClasses.row}.odd`]: {
						backgroundColor: palette.primary.light_3
					},
					'& .MuiDataGrid-columnHeaders': {
						borderRadius: 0,
						borderWidth: palette.mode == 'dark' ? 0 : 1
					},
					'& .MuiDataGrid-main': {
						...(palette.mode !== 'dark' && {
							border: '1px solid #00000020'
						})
					},
					'& .MuiSvgIcon-root': {
						color: palette.primary.text.dark
					},
					'& .MuiDataGrid-virtualScrollerContent': {
						backgroundColor: palette.primary.light_2
					},
					'& .MuiPopover-paper': {
						backgroundColor: `${palette.fixed.black}!important`,
						color: `${palette.fixed.white}!important`
					},
					'& .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root': {
						color: `${palette.fixed.white}!important`,
						backgroundColor: `${palette.fixed.black}!important`
					},
					'& .MuiDataGrid-cell--withRenderer': {
						'& .MuiSvgIcon-root': {
							color: `${palette.colors.icons}!important`
						},
						"& .MuiDataGrid-booleanCell[data-value='false']": {
							color: `${palette.colors.cancel_icon}!important`
						}
					},
					'& .MuiPaper-root': {
						'& .MuiMenu-list': {
							backgroundColor: `red!important`
						},
						'& .MuiMenu-paper': {
							backgroundColor: `red!important`
						},
						'& .MuiPaper-root': {
							backgroundColor: `red!important`
						},
						'& .MuiPopover-paper': {
							backgroundColor: `red!important`
						}
					},
					'& .css-1smlk3z-MuiPaper-root': {
						backgroundColor: '#000'
					}
				}}
				onCellClick={isForDashboard ? null : onCellClick}
				onCellDoubleClick={isForDashboard ? null : useLinkColumn === false && onCellDoubleClick}
				columns={gridColumns}
				pageSize={pageSize}
				rowsPerPageOptions={[5, 10, 20, 50, 100]}
				onPageSizeChange={setPageSize}
				onPageChange={setPage}
				pagination
				rowCount={rowCount}
				rows={data.rows}
				paginationMode={'server'}
				sortModel={sortModel}
				sortingMode={isClient}
				onSortModelChange={setSortModel}
				filterMode={isClient}
				onFilterModelChange={(model) => setFilterModel(prevState => handleFilterModelChange(model, prevState))}
				getRowId={getGridRowId}
				components={{
					Toolbar: CustomToolbar
				}}
				rowThreshold={0}
				onColumnVisibilityModelChange={(newModel) => {
					setAllColumns(newModel);
				}}
				hideFooterSelectedRowCount={true}
				classes={{
					root: classes.root,
					cell: classes.cell,
					columnHeader: classes.header,
					footerContainer: classes.footer,
					panelFooter: classes.panelFooter,
					menu: classes.menu,
					paper: classes.paper
				}}
				density="compact"
				disableDensitySelector={true}
				disableMultipleColumnsFiltering
				disableMultipleColumnsSorting
				experimentalFeatures={{ newEditingApi: true }}
				componentsProps={{
					toolbar: { showQuickFilter: true },
					panel: {
						sx: {
							'& .MuiTypography-root': {
								color: palette.primary.text.dark,
								fontSize: 20,
								fontWeight: '300!important'
							},
							'& .MuiDataGrid-panelHeader': {
								bgcolor: palette.primary.main,
								color: palette.primary.text.dark,
								'& input': {
									color: palette.primary.text.dark
								}
							},
							'& .MuiDataGrid-columnsPanel': {
								bgcolor: palette.primary.light_2,
								fontSize: 10
							},
							'& .MuiDataGrid-filterForm': {
								bgcolor: palette.primary.light_2,
								'& .MuiTypography-root': {
									fontSize: 10,
									fontWeight: '300'
								}
							},
							'& .MuiNativeSelect-select': {
								color: palette.primary.text.dark
							},
							'& .MuiNativeSelect-select option': {
								background: `${palette.primary.light_2}!important`,
								color: palette.primary.text.dark,
								fontWeight: '300',
								textTransform: 'capitalize'
							},
							'& input': {
								color: palette.primary.text.dark,
								fontWeight: '300'
							},
							'& .MuiDataGrid-panelFooter': {
								backgroundColor: palette.primary.light_2,
								gap: 3,
								padding: '9px 16px 10px',
								borderTop: `1px solid ${palette.primary.text.medium}`,
								color: palette.primary.text.dark,
								'& button': {
									flex: 1,
									border: `1px solid ${palette.primary.text.dark}`,
									color: palette.primary.text.dark,
									fontWeight: '300',
									padding: '6px 0px',
									fontSize: '13px'
								}
							}
						}
					},
					footer: {
						sx: {
							'& .MuiPaper-elevation': {
								backgroundColor: '#000!important',
								color: palette.primary.text.dark
							},
							'& .MuiPaginationItem-root': {
								backgroundColor: '#000!important'
							}
						}
					}
				}}
			/>
		</>
	);
};

export default GridBase;
