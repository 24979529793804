import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';

const exportBlob = (blob, filename) => {
    // Save the blob in a json file
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();

    setTimeout(() => {
        URL.revokeObjectURL(url);
    });
};

const ExportMenuItem = (props) => {

    const { hideMenu, onGetData, type } = props;
    return (
        <MenuItem
            onClick={async () => {
                const data = await onGetData(type);
                const blob = new Blob([data.data], {
                    type: type === 'excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' : `application/${type.toLowerCase()}`,
                });
                exportBlob(blob, data.fileName);
                // Hide the export menu after the export
                hideMenu?.();
            }}
        >
            Export {type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}
        </MenuItem>
    );
};

ExportMenuItem.propTypes = {
    hideMenu: PropTypes.func
};

export default ExportMenuItem;
